import { isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';

const linkedInScriptContent = `
_linkedin_partner_id = ${environment.LINKEDIN.ANALYTICS_ID};
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);

(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);
`;

export function initializeLinkedInAnalytics() {
  if (isDevMode()) {
    console.log('Loading LinkedIn Analytics...');
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  const blob = new Blob([linkedInScriptContent], { type: 'text/javascript' });
  const url = URL.createObjectURL(blob);
  script.src = url;
  document.head.appendChild(script);
}
