import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faSpinner, faTrash, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Region } from './region.interface';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationClass } from 'src/app/shared/classes/notification.class';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { Account } from 'src/app/shared/models';
import { MatDialog } from '@angular/material/dialog';
import { RegionEditDialog } from '../region-edit-dialog/region-edit-dialog.component';

@Component({
  selector: 'app-reserved-regions',
  templateUrl: './reserved-regions.component.html',
  styleUrls: ['./reserved-regions.component.scss'],
})
export class ReservedRegionsComponent extends NotificationClass implements OnInit {
  public regions: Region[] = [];
  public users: Account[] = [];
  public orgNameList: string[] = [];

  public readonly regionColumns: string[] = ['region', 'orgId', 'orgName', 'email', 'iso2', 'iso3', 'actions'];
  public readonly editIcon: IconDefinition = faEdit;
  public readonly trash: IconDefinition = faTrash;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public readonly question: IconDefinition = faQuestionCircle;

  public addContextOpen = false;
  public isLoading = false;
  public formGroup: UntypedFormGroup;

  public filteredOrgs: Observable<string[]>;

  constructor(
    protected _snackBar: MatSnackBar,
    private _rest: RestAPIService,
    private _confirm: ConfirmationService,
    public formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {
    super(_snackBar);
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      organization: ['', Validators.required],
      iso2: ['', Validators.required],
      iso3: ['', Validators.required],
    });
  }

  async ngOnInit() {
    await this._getRegions();

    this.filteredOrgs = this.formGroup.get('organization').valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val)),
    );
  }

  private async _getRegions() {
    this.isLoading = true;
    await this._getOrgs();
    this.regions = await this._rest.get('regions', {
      msg: 'Could not get regions',
    });
    this._insertOrgName();
    this.isLoading = false;
  }

  private async _getOrgs() {
    const response = await this._rest.get('admin/users');
    this.users = response.users.filter((res) => res.organization !== undefined);
    this.orgNameList = this.users.map((org) => org.organization.name);
  }

  closeAddContext() {
    this.addContextOpen = false;
    this.formGroup.reset();
  }

  public checkFormDisabled(): boolean {
    const controls = this.formGroup.controls;
    return Object.keys(controls).some((key: string) => {
      return controls[key].touched && !controls[key].valid;
    });
  }

  private _insertOrgName() {
    this.regions = this.regions.map((region) => {
      const {
        organization: { name },
      } = this.users.find((org) => org.organization.id === region.orgId);
      return {
        ...region,
        orgName: name,
      };
    });
  }

  public filter(val: string) {
    if (val != null) {
      return this.orgNameList.filter((option) => option.toLowerCase().includes(val));
    }
  }

  public async saveRegion() {
    const formVal = this.formGroup.getRawValue();
    const [orgInfo] = this.users.filter((org) => org.organization.name === formVal.organization);
    formVal.orgId = orgInfo.organization.id;

    const regionForm = {
      region: {
        name: formVal.name,
        orgId: orgInfo.organization.id,
        iso2: formVal.iso2,
        iso3: formVal.iso3,
      },
    };

    await this._rest.post('region', regionForm, { msg: 'Could not post region' });
    this.notify('Region was created successfully!');
    this._getRegions();
  }

  public async deleteRegion(orgId: string) {
    const warning = 'Are you sure you want to delete this region?';

    this._confirm.createConfirmation('Warning', warning, 'Yes', 'No').then(async () => {
      await this._rest.delete('region/id/' + orgId, { msg: 'Could not delete region.' });
      await this._getRegions();
    });

    this._getRegions();
  }

  public getRegionEmail(region: Region) {
    const regionOrg = this.users.find((u) => u.organization.id === region.orgId);

    return region.email ?? regionOrg.email;
  }

  public editRegion(region: Region) {
    const dialog = this.dialog.open(RegionEditDialog, {
      panelClass: 'modal-border',
      data: {
        region,
        orgList: this.users.map((u) => u.organization).filter((o) => o.name),
      },
      width: '550px',
      height: '500px',
      disableClose: true,
    });

    dialog.afterClosed().subscribe((shouldReload) => {
      if (shouldReload) {
        this.ngOnInit();
      }
    });
  }

  public createNewRegion() {
    const dialog = this.dialog.open(RegionEditDialog, {
      panelClass: 'modal-border',
      data: {
        orgList: this.users.map((u) => u.organization).filter((o) => o.name),
      },
      width: '550px',
      height: '500px',
      disableClose: true,
    });

    dialog.afterClosed().subscribe((shouldReload) => {
      if (shouldReload) {
        this.ngOnInit();
      }
    });
  }
}
