<link
  rel="stylesheet"
  href="/assets/styles/webgl.css"
/>
<div
  class="webgl-content m-auto"
  [ngClass]="{ hide: loading }"
>
  <div id="gameContainer">
    <canvas
      id="gameCanvas"
      style="width: 960px; height: 540px"
    >
    </canvas>
  </div>
  <div class="footer">
    <!-- <div class="webgl-logo"></div> -->
    <span> Click the arrows to enlarge your screen </span>
    <div
      class="fullscreen"
      (click)="setFullscreen()"
    ></div>
  </div>
</div>

<div
  class="loading"
  [ngClass]="{ hide: !loading }"
>
  <div class="col-2 px-0">
    <div class="col-12 px-0 loadingText">
      <img
        [ngStyle]="{ width: '160px', padding: '10px' }"
        src="../../../../assets/img/NeurAlign text logo.png"
      />
    </div>
    <div class="col-8 px-0 m-auto">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
