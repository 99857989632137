import { Component } from '@angular/core';
import { LessonsService } from 'src/app/pages/courses/services/lessons/lessons.service';

@Component({
  selector: 'prev-lesson-button',
  templateUrl: './prev-section-button.component.html',
  styleUrls: ['./prev-section-button.component.scss'],
})
export class PrevSectionButtonComponent {
  public currentLesson$ = this._lessons.currentLesson$;

  public label = 'Previous Lesson';

  constructor(private _lessons: LessonsService) {}

  handleClick() {
    this._lessons.selectPrevLesson();
  }
}
