<div class="mainContainer col-12 px-0">
  <div class="header text-center col-12">Categories</div>

  <div class="col-12 px-0 mt-5 dataContainer">
    <div class="title">Category Icons</div>

    <div class="categoryIcon d-flex justify-content-center align-items-end">
      <div class="mx-2 text-center">
        <img
          [ngClass]="{ placeHolder: imageIsPlaceHolder(newCategory.img) }"
          [src]="newCategory.img"
        />

        <div class="my-2 text-center">
          <app-simple-button
            [text]="'Add avaliable icon'"
            (click)="addCategoryImage('img')"
          >
          </app-simple-button>
        </div>
      </div>

      <div class="mx-2 text-center">
        <img
          [ngClass]="{ placeHolder: imageIsPlaceHolder(newCategory.lockedImg) }"
          [src]="newCategory.lockedImg"
        />

        <div class="my-2 text-center">
          <app-simple-button
            [text]="'Add locked icon'"
            (click)="addCategoryImage('lockedImg')"
          >
          </app-simple-button>
        </div>
      </div>
    </div>

    <div class="title mt-3">Category Info</div>

    <div class="info my-3 mx-auto">
      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Name </span>
        </div>

        <div class="mx-2">
          <input
            name="name"
            class="text-center basicInput mx-2"
            [(ngModel)]="newCategory.name"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Max Sessions </span>
        </div>

        <div class="mx-2">
          <input
            name="sessions"
            class="text-center basicInput mx-2"
            type="number"
            [(ngModel)]="newCategory.maxSessions"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Sessions interval </span>
        </div>

        <div class="mx-2">
          <input
            name="sessionIntervals"
            class="text-center basicInput mx-2"
            type="number"
            [(ngModel)]="newCategory.sessionInterval"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Graph Type </span>
        </div>

        <div class="mx-2">
          <select
            class="basicInput basicSelectable graphSelectable"
            [(ngModel)]="newCategory.graphType"
          >
            <option
              class="p-2"
              *ngFor="let graph of graphsList | keyvalue"
            >
              {{ graph.value }}
            </option>
          </select>
        </div>

        <fa-icon
          [icon]="preview"
          (click)="openGraphPreviewDialog()"
          class="d-flex align-items-center"
        ></fa-icon>
      </div>
    </div>

    <div class="title">Unlock Conditions</div>

    <div class="unlockConditions text-center">
      <select
        class="basicInput basicSelectable"
        [(ngModel)]="categoryName"
        [ngClass]="{ disabledButton: filteredCategories.length === 0 }"
      >
        <option
          (click)="resetSession()"
          class="p-2"
          *ngFor="let category of filteredCategories; let i = index"
        >
          {{ category.name }}
        </option>
      </select>

      <input
        [ngClass]="{ disabledButton: filteredCategories.length === 0 }"
        limit-to-max
        [max]="getCategoryMaxSession()"
        min="0"
        name="unlockConditionSession"
        class="text-center basicInput mx-2"
        type="number"
        [(ngModel)]="categorySession"
        autocomplete="off"
      />

      <button
        [ngClass]="{ disabledButton: filteredCategories.length === 0 }"
        class="basicButton"
        (click)="addNewCondition()"
      >
        Add new
      </button>
    </div>

    <div
      class="text-center my-3 warning"
      *ngIf="newCategory.unlockCondition.length === 0"
    >
      You dont have any unlock conditions for this category.
    </div>

    <div
      class="unlockConditionsList"
      *ngIf="newCategory.unlockCondition.length > 0"
    >
      <table
        mat-table
        [dataSource]="unlockConditionsDataSource"
        class="col-8 px-0 m-auto"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="category">
          <th
            class="text-center"
            mat-header-cell
            *matHeaderCellDef
          >
            Category
          </th>
          <td
            class="text-center"
            mat-cell
            *matCellDef="let condition"
          >
            {{ condition.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="session">
          <th
            class="text-center"
            mat-header-cell
            *matHeaderCellDef
          >
            Session
          </th>
          <td
            class="text-center"
            mat-cell
            *matCellDef="let condition"
          >
            {{ condition.session }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            class="text-center"
            mat-header-cell
            *matHeaderCellDef
          >
            Actions
          </th>
          <td
            class="text-center"
            mat-cell
            *matCellDef="let condition"
          >
            <fa-icon
              [icon]="remove"
              matToolTip="Remove condition"
              (click)="removeCondition(condition)"
            >
            </fa-icon>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="conditionsColums"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: conditionsColums"
          class="example-element-row"
        ></tr>
      </table>
    </div>

    <div class="title my-2">Games List</div>

    <table
      mat-table
      [dataSource]="gamesDataSource"
      matSort
      class="col-8 px-0 m-auto"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="games">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Game
        </th>
        <td
          mat-cell
          *matCellDef="let game"
        >
          {{ gamesList[game] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Enabled
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let game"
        >
          <mat-checkbox
            (click)="addGameToList(game)"
            *ngIf="!isGameEnabled(game)"
            [(ngModel)]="unchecked"
          >
          </mat-checkbox>

          <mat-checkbox
            (click)="addGameToList(game)"
            *ngIf="isGameEnabled(game)"
            [(ngModel)]="checked"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="gamesColums"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: gamesColums"
        class="example-element-row"
      ></tr>
    </table>

    <div class="button col-12 px-0 d-flex justify-content-center my-4">
      <app-simple-button
        [text]="'Save category'"
        (click)="saveCategory()"
      >
      </app-simple-button>
    </div>
  </div>
</div>
