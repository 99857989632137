import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RestAPIService } from '../../../services/rest/rest-api.service';
import { User } from '../../models/user.model';
import { NotificationClass } from '../../classes/notification.class';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { get, isEmpty } from 'lodash';
import { OrganizationsListService } from 'src/app/pages/users/menus/organizations-menu/organizations-list/organizations-list.service';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';

enum FormType {
  OUTSIDER = 'Outsider',
  ORGANIZATION = 'Organization',
  PATRON = 'Patron',
  PASSWORD = 'Password',
}

declare let cloudinary: any;
declare let window: any;

@Component({
  selector: 'app-register-form',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterFormComponent extends NotificationClass implements OnInit {
  @Input() reseller = '';
  @Input() outsiderOrgId = '';

  public patronFormGroup: UntypedFormGroup;
  public orgFormGroup: UntypedFormGroup;
  public passwordFormGroup: UntypedFormGroup;
  public action: string;
  public formType: string;
  public editing = false;
  public readonly spinner: IconDefinition = faSpinner;

  public organizationForm = false;
  public patronForm = false;
  public passwordForm = false;
  public savingUser = false;

  public languages = [
    {
      title: 'English',
      value: 'en',
    },
    {
      title: 'French',
      value: 'fr',
    },
  ];

  // This pattern prevents the inputs to be only white spaces
  public noWhiteSpace = /^[\S\s]+$/;

  constructor(
    protected _snackBar: MatSnackBar,
    private _restService: RestAPIService,
    private _router: Router,
    private _auth: AuthService,
    private _organizationListService: OrganizationsListService,
  ) {
    super(_snackBar);
  }

  async ngOnInit() {
    const queryParams = this._router['currentUrlTree']['queryParams'];
    this.action = undefined;

    const currentUser = await this._auth.getUser();

    if (isEmpty(queryParams) === false && currentUser) {
      this.editing = true;
      this.action = queryParams.action;
    }

    this.formType = await this._getFormType();
    this.createFormGroups(currentUser);

    switch (this.formType) {
      case FormType.ORGANIZATION:
        await this._loadOrganization();
        localStorage.setItem('LSUserType', 'organization');
        break;
      case FormType.PATRON:
        await this._loadPatron();
        localStorage.setItem('LSUserType', 'patron');
        break;
      case FormType.OUTSIDER:
        await this._loadOutsider();
        localStorage.setItem('LSUserType', 'organization');
        break;
      default:
        break;
    }
  }

  public createFormGroups(user: User) {
    this.patronFormGroup = this._createPatronFormGroup();
    this.passwordFormGroup = this._createPasswordFormGroup();
    this.orgFormGroup = this._createOrgFormGroup(user);
  }

  public fillSubdomain(): void {
    const companyName = this.orgFormGroup.get('name').value;
    if (companyName && companyName.trim() !== '') {
      this.orgFormGroup.get('subdomain').setValue(this._getCompanyNameSubdomain(companyName));
    }
  }

  public checkFormDisabled(): boolean {
    if (!this.orgFormGroup) {
      return false;
    }
    const controls = this.formType === FormType.PATRON ? this.patronFormGroup.controls : this.orgFormGroup.controls;

    return Object.keys(controls).some((key: string) => {
      return controls[key].touched && !controls[key].valid;
    });
  }

  public uploadToCloudinary() {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.orgFormGroup.get('logo').setValue(secure_url || url);
      } else if (error && error.message !== 'User closed widget') {
        this._snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public async savePassword() {
    this.savingUser = true;
    const passwordValue = this.passwordFormGroup.get('password').value;
    await this._restService.post('user/password', {
      password: passwordValue,
    });

    await this._auth.getUser(true);
    this._router.navigate(['/']);
  }

  public async savePatron(user: User) {
    try {
      const response = await this._restService.put('patron/self', {
        patron: user.patron,
      });

      if (!response) {
        throw new Error('User update failed! Please try again. If the problem persist contact our support');
      }

      this.notify('User updated!', 'Close', 2000);
    } catch (error) {
      this.notify(error.message, 'Close', 2000);
    }
  }

  public async saveOrganization(user: User) {
    try {
      const response = await this._restService.put('organization/self', {
        organization: user.organization,
      });

      if (!response) {
        throw new Error('User update failed! Please try again. If the problem persist contact our support');
      }

      this.notify('User updated!', 'Close', 2000);
    } catch (error) {
      this.notify(error.message, 'Close', 2000);
    }
  }

  public async createOutsider(user: User) {
    const currentUser = await this._auth.getUser();
    const currentOrg = this._auth.getOrgAcc();

    try {
      user.organization.isOutsider = true;
      await this._restService.post('organization/outsider', {
        user,
        reseller: currentUser.id,
        verifyType: 'outsider',
        org: currentUser.organization.name,
        subdomain: user.organization.subdomain,
        orgEmail: currentOrg.email,
        lang: user.organization.language,
      });

      this.notify('User created!', 'Close', 2000);
      this._organizationListService.getOrganizations({ refresh: true });
      this._router.navigate(['/']);
    } catch (error) {
      this.notify(error.message, 'Close', 2000);
    }
  }

  public async saveOutsider(user: User) {
    try {
      const response = await this._restService.get(`organization/outsider/${this.outsiderOrgId}`);

      if (!response) {
        throw new Error('Could not update this user! Please try again. If the problem persist contact our support');
      }

      const {
        organization: { email },
      } = response;
      user.organization.email = email;

      const outsider = await this._restService.put('organization/outsider', {
        organization: user.organization,
        outsiderId: this.outsiderOrgId,
      });

      if (!outsider) {
        throw new Error('Could create this user! Please try again. If the problem persist contact our support');
      }

      this.notify('User updated!', 'Close', 2000);
      this._organizationListService.getOrganizations({ refresh: true });
    } catch (error) {
      this.notify(error.message, 'Close', 2000);
    }
  }

  public async handlePatronForm(user: User) {
    // Add any logic related to the patron form here
    user.patron = this.patronFormGroup.getRawValue();
    const [givenName, familyName = ''] = user.patron.name.split(' ');
    user.patron.givenName = givenName;
    user.patron.familyName = familyName;
    this.savePatron(user);
  }

  public async handleOrganizationForm(user: User) {
    // Add any logic related to the organization form here
    await this.saveOrganization(user);
  }

  public async handleOutsiderForm(user: User) {
    // Add any logic related to the outsider form here

    if (this.editing && this.outsiderOrgId) {
      this.saveOutsider(user);
      return;
    }

    await this.createOutsider(user);
  }

  public async saveUser() {
    try {
      this.savingUser = true;
      let user = new User();

      switch (this.formType) {
        case FormType.PASSWORD:
          await this.savePassword();
          break;
        case FormType.PATRON:
          user.patron = this.patronFormGroup.getRawValue();
          await this.handlePatronForm(user);
          break;
        case FormType.ORGANIZATION:
          user = this._handleOrgInfo(user);
          await this.handleOrganizationForm(user);
          break;
        case FormType.OUTSIDER:
          user = this._handleOrgInfo(user);
          await this.handleOutsiderForm(user);
          break;
        default:
          break;
      }
      await this._auth.getUser(true);
      this.savingUser = false;
    } catch (error) {
      this.savingUser = false;
      this.notify(error.error);
    }
  }

  public passMatch(): boolean {
    const passwordValue = this.passwordFormGroup && this.passwordFormGroup.get('password').value;
    const confirmPasswordValue = this.passwordFormGroup && this.passwordFormGroup.get('confirmPassword').value;
    return passwordValue === confirmPasswordValue;
  }

  public isPasswordValid(): boolean {
    const passwordValue = this.passwordFormGroup.get('password').value;

    const lowercaseOnly = /[a-z]/g;
    const lowerValue = lowercaseOnly.test(passwordValue) ? 1 : 0;

    const uppercaseOnly = /[A-Z]/g;
    const upperValue = uppercaseOnly.test(passwordValue) ? 1 : 0;

    const number = /[0-9]/g;
    const numberValue = number.test(passwordValue) ? 1 : 0;

    const specialCharacters = /[!@#$%^&*]/g;
    const specialCharactersValue = specialCharacters.test(passwordValue) ? 1 : 0;

    const totalPassChecks = lowerValue + upperValue + numberValue + specialCharactersValue;

    return passwordValue.length > 8 && this.passMatch() && totalPassChecks >= 3;
  }

  public isOrgFormValid(): boolean {
    if (!this.orgFormGroup) {
      return false;
    }
    const acceptedTerms =
      this.formType === FormType.ORGANIZATION && !this.editing ? this.orgFormGroup.get('acceptedTerms').value : true;
    return this.orgFormGroup.valid && acceptedTerms;
  }

  private isOutsiderForm(): boolean {
    return this.reseller !== undefined && this.reseller.length > 0;
  }

  private isOrgForm(user: User): boolean {
    if (!user) {
      return false;
    }

    return user.organization !== undefined;
  }

  private isPatronForm(user: User): boolean {
    if (!user) {
      return false;
    }

    return user.patron !== undefined;
  }

  private async _getFormType() {
    if (this.action) {
      return this.action;
    }

    const user = await this._auth.getUser();

    if (this.isOutsiderForm()) {
      return FormType.OUTSIDER;
    }

    if (this.isOrgForm(user)) {
      return FormType.ORGANIZATION;
    }

    if (this.isPatronForm(user)) {
      return FormType.PATRON;
    }
  }

  private async _loadPatron(): Promise<void> {
    if (this.editing) {
      try {
        const response = await this._restService.get('patron/self', { msg: 'Could not get patron.' });
        const zip = document.querySelector('.postalCode') as HTMLInputElement;

        this.patronFormGroup.setValue({
          name: get(response, 'patron.givenName', '') + get(response, 'patron.familyName', ''),
          email: get(response, 'patron.email', ''),
          country: get(response, 'patron.country', ''),
          postalCode: get(response, 'patron.postalCode', ''),
        });

        zip.value = get(response, 'patron.postalCode', '');

        this.editing = this.formType !== 'Outsider';
      } catch {
        this.notify(
          'Could not load the client! Please refresh your page. If the problem persist contact our support',
          'Close',
          2000,
        );
      }
    }
  }

  private async _loadOrganization(): Promise<void> {
    if (this.editing) {
      try {
        const response = await this._restService.get('organization/self', {
          msg: 'Could not get organization.',
        });
        const addr = document.querySelector('.address') as HTMLInputElement;
        const zip = document.querySelector('.postalCode') as HTMLInputElement;

        this.orgFormGroup.setValue({
          name: get(response, 'organization.name', ''),
          subdomain: get(response, 'organization.subdomain', ''),
          address: {
            country: get(response, 'organization.address.country', ''),
            address: get(response, 'organization.address.address', ''),
            province: get(response, 'organization.address.province', ''),
            postalCode: get(response, 'organization.address.postalCode', ''),
          },
          phoneNumber: get(response, 'organization.phoneNumber', ''),
          position: get(response, 'organization.position', ''),
          professionalAssociation: get(response, 'organization.professionalAssociation', ''),
          logo: get(response, response.organization.logo.replace('http://', 'https://'), ''),
          language: get(response, 'organization.acceptedTerms ', 'en'),
          referredBy: get(response, 'organization.referredBy ', ''),
        });
        addr.value = get(response, 'organization.address.address', '');
        zip.value = get(response, 'organization.address.postalCode', '');

        this.editing = this.formType !== 'Outsider';
      } catch (error) {
        this.notify(
          'Could not load the organization! Please refresh your page. If the problem persist contact our support',
          'Close',
          2000,
        );
      }
    }
  }

  private async _loadOutsider(): Promise<void> {
    try {
      const response = this.outsiderOrgId
        ? await this._restService.get('organization/outsider/' + this.outsiderOrgId)
        : undefined;

      const addr = document.querySelector('.address') as HTMLInputElement;
      const zip = document.querySelector('.postalCode') as HTMLInputElement;

      if (!this.orgFormGroup || !addr || !zip) {
        return;
      }

      this.orgFormGroup.setValue({
        name: get(response, 'organization.name', ''),
        subdomain: get(response, 'organization.subdomain', ''),
        address: {
          country: get(response, 'organization.address.country', ''),
          address: get(response, 'organization.address.address', ''),
          province: get(response, 'organization.address.province', ''),
          postalCode: get(response, 'organization.address.postalCode', ''),
        },
        professionalAssociation: get(response, 'organization.professionalAssociation', ''),
        phoneNumber: get(response, 'organization.phoneNumber', ''),
        position: get(response, 'organization.position', ''),
        logo: get(response, 'organization.logo', ''),
        language: get(response, 'organization.language', 'en'),
        referredBy: get(response, 'organization.referredBy', ''),
      });

      addr.value = get(response, 'organization.address.address', '');
      zip.value = get(response, 'organization.address.postalCode', '');
    } catch (error) {
      this.notify(
        'Could not load the organization! Please refresh your page. If the problem persist contact our support',
        'Close',
        2000,
      );
    }
  }

  private _createPatronFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.pattern(this.noWhiteSpace)]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      country: new UntypedFormControl('', [Validators.required]),
      postalCode: new UntypedFormControl('', [Validators.required]),
    });
  }

  private _createPasswordFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    });
  }

  private getOrgEditFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.pattern(this.noWhiteSpace)]),
      subdomain: new UntypedFormControl('', [Validators.required]),
      address: new UntypedFormGroup({
        country: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
        address: new UntypedFormControl('', [Validators.required]),
        province: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
        postalCode: new UntypedFormControl('', [Validators.required]),
      }),
      referredBy: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      language: new UntypedFormControl('en', [Validators.required]),
      position: new UntypedFormControl('', [Validators.required]),
      professionalAssociation: new UntypedFormControl(''),
      logo: new UntypedFormControl('./assets/img/Image_Placeholder.jpg'),
    });
  }

  private getOrgCreationFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      name: new UntypedFormControl('', [Validators.required, Validators.pattern(this.noWhiteSpace)]),
      subdomain: new UntypedFormControl('', [Validators.required]),
      address: new UntypedFormGroup({
        country: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
        address: new UntypedFormControl('', [Validators.required]),
        province: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
        postalCode: new UntypedFormControl('', [Validators.required]),
      }),
      referredBy: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      language: new UntypedFormControl('en', [Validators.required]),
      position: new UntypedFormControl('', [Validators.required]),
      professionalAssociation: new UntypedFormControl(''),
      acceptedTerms: new UntypedFormControl(false),
      logo: new UntypedFormControl('./assets/img/Image_Placeholder.jpg'),
    });
  }

  private _createOrgFormGroup(currentUser: User): UntypedFormGroup {
    this.editing = this.formType !== FormType.OUTSIDER && isEmpty(currentUser) === false;

    if (this.formType === FormType.OUTSIDER && !this.outsiderOrgId) {
      return this.getOrgCreationFormGroup();
    }

    if (this.editing) {
      return this.getOrgEditFormGroup();
    }

    return this.getOrgCreationFormGroup();
  }

  private _getCompanyNameSubdomain(companyName: string): string {
    return companyName
      .toLowerCase()
      .split(' ')
      .join('')
      .replace(/[^a-zA-Z0-9]/g, '');
  }

  private _handleOrgInfo(user: User): User {
    user.organization = this.orgFormGroup.getRawValue();

    if (!user.organization.logo || user.organization.logo.trim() === '') {
      user.organization.logo =
        'https://ui-avatars.com/api/?background=fff&color=3A3372&size=128&name=' + user.organization.name;
    }

    return user;
  }

  fillFormControl(param) {
    const input = document.querySelector(`.${param}`) as HTMLInputElement;

    if (this.formType === 'Organization') {
      if (input.value) {
        const formValue = this.orgFormGroup.get(`address.${param}`).value;

        if (!formValue) {
          this.orgFormGroup.get(`address.${param}`).setValue(input.value);
        }
      }
    }
  }

  /**
   * Handle Google Autocomplete by populating other address
   * fields when user changes address
   *
   * @param place An object provided by Google that has all the details of a place the user selects.
   */
  handleAddressChange(place) {
    const location = setLocation();

    function setLocation() {
      const streetNumber = getAddressComponent(['street_number']);
      const route = getAddressComponent(['route']);
      const locality = getAddressComponent(['locality', 'sublocality', 'neighborhood']);
      const postalCode = getAddressComponent(['postal_code']);
      const country = getAddressComponent(['country']);
      const province = getAddressComponent(['administrative_area_level_1']);

      return {
        streetNumber,
        route,
        locality,
        postalCode,
        country,
        province,
      };
    }

    function getAddressComponent(types: string[]) {
      const { address_components } = place;

      let result;

      // Loop through the address types and return the
      // result when it is populated with data
      for (let i = 0; i < types.length; i++) {
        result = address_components.find((item) => get(item, 'types').includes(types[i]));

        if (result) {
          return result.long_name;
        }
      }

      if (!result) {
        // Soft error: Not all places that Google provides have all
        // the address components we need. If some are blank
        // this function will return an empty string
        return '';
      }
    }

    // Set the rest of the inputs when a user changes their location
    if (this.formType === FormType.PATRON) {
      // Update Patron form inputs
      this.patronFormGroup.get('postalCode').setValue(location.postalCode);
      this.patronFormGroup.get('country').setValue(location.country);
    } else {
      // Update Organization form inputs
      this.orgFormGroup
        .get('address.address')
        .setValue(`${location.streetNumber} ${location.route}, ${location.locality}`);
      this.orgFormGroup.get('address.postalCode').setValue(location.postalCode);
      this.orgFormGroup.get('address.country').setValue(location.country);
      this.orgFormGroup.get('address.province').setValue(location.province);
    }
  }

  public getOrgLogo() {
    return this.orgFormGroup.get('logo').value || './assets/img/Image_Placeholder.jpg';
  }
}
