import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';
import { Styles } from 'src/app/shared/styles/lsw.styles';

@Component({
  selector: 'home-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  @ViewChild('bg') bg: ElementRef;
  @ViewChild('box') box: ElementRef;

  constructor(private _renderer: Renderer2, private _router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    styleElement(this._renderer, this.box, {
      position: 'absolute',
      left: clamp(1, 12),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: clamp(0.3, 3),
    });

    styleElement(this._renderer, this.bg, { backgroundColor: Styles.Colours.SECONDARY_COLOUR });
  }

  goToDemo() {
    window.open('https://lsworks.org/demo', '_blank');
  }
}
