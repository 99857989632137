<ng-container *ngIf="template === 'table'">
  <users-table-header heading="Clients"></users-table-header>

  <div class="search-and-sort">
    <users-table-search-input
      placeholder="Search clients..."
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>

    <app-simple-button
      [text]="sortOrderLabel"
      (click)="reverseClientListOrder()"
      [smaller]="true"
    ></app-simple-button>
  </div>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell
          *matCellDef="let client"
          (click)="getStudents(client.id)"
          class="clickable"
          [matTooltip]="'Click for client students'"
        >
          {{ client.name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let client">
          <users-table-status-item
            [status]="client.status"
          ></users-table-status-item>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let client">
          <div class="icons">
            <users-table-action-icon
              [icon]="'plus'"
              [tooltip]="'Add student'"
              (click)="addStudent(client.id)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit user'"
              (click)="editClient(client)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'redo'"
              [tooltip]="getEmailVerifiedTooltip(client.emailVerified)"
              (click)="resendVerificationEmail(client)"
              [disabled]="client.emailVerified"
            ></users-table-action-icon>

            <ng-container>
              <input
                type="file"
                (change)="importStudentsForFile($event, client)"
                style="display: none"
                #fileInput
              />
              <users-table-action-icon
                [icon]="'upload'"
                [tooltip]="'Import students from a file'"
                (click)="fileInput.click()"
              >
              </users-table-action-icon>
            </ng-container>

            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete client'"
              (click)="deleteClient(client.id)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="['name', 'status', 'actions']; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'status', 'actions']"
      ></mat-row>
    </mat-table>

    <ng-container *ngIf="isDataSourceEmpty()">
      <users-empty-state
        class="no-users"
        text="There are currently no clients. You can add a client by clicking the Add New button in the top right corner."
        template="noData"
      ></users-empty-state>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
