import { isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';

// Define the content of the script
const clarityScriptContent = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${environment.CLARITY_ID}");
  `;

export function initializeClarity() {
  if (isDevMode()) {
    console.log('Loading Clarity...');
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';

  // Use a blob to create a URL for the script content
  const blob = new Blob([clarityScriptContent], { type: 'text/javascript' });
  const url = URL.createObjectURL(blob);

  // Set the src attribute to the blob URL
  script.src = url;

  // Append the script to the head
  document.head.appendChild(script);
}
