import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../../../../../services/event/event.service';
import { LessonsService } from '../../../../../services/lessons/lessons.service';

@Component({
  selector: 'next-section-button',
  templateUrl: './next-section-button.component.html',
  styleUrls: ['./next-section-button.component.scss'],
})
export class NextSectionButtonComponent implements OnInit, OnDestroy {
  currentLessonSub: Subscription;
  private isReadyForNextLessonSub: Subscription;
  label = 'Next Lesson';
  isReady: boolean;

  // INIT --

  constructor(private _lessons: LessonsService, private _events: EventService) {}

  ngOnInit() {
    this.currentLessonSub = this._lessons.currentLesson$.subscribe((lesson) => {
      this.setComponent(lesson);
    });

    this.isReadyForNextLessonSub = this._events.isReadyForNextLessonEvent$.subscribe((bool) => {
      this.isReady = bool;
    });
  }

  ngOnDestroy() {
    this.currentLessonSub.unsubscribe();
    this.isReadyForNextLessonSub.unsubscribe();
  }

  // API --

  public async handleClick() {
    await this._lessons.selectNext();
  }

  // PRIVATE --
  private setComponent(lesson) {
    this.isReady = true;
    if (lesson.isCompleted) {
      this.label = 'Next Lesson';
    } else {
      this.label = 'Complete & Continue';
    }
  }
}
