import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';

@Component({
  selector: 'home-dyslexia-def',
  templateUrl: './dyslexia-def.component.html',
  styleUrls: ['./dyslexia-def.component.scss'],
})
export class DyslexiaDefComponent implements AfterViewInit {
  @ViewChild('definitionBox') definitionBox: ElementRef;
  @ViewChild('dyslexiaBox') dyslexiaBox: ElementRef;
  @ViewChild('text') text: ElementRef;
  @ViewChild('list') list: ElementRef;
  @ViewChild('brain') brain: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setStyles();
  }

  setStyles() {
    styleElement(this._renderer, this.definitionBox, {
      position: 'relative',
      height: clamp(12, 60),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    });

    styleElement(this._renderer, this.dyslexiaBox, {
      position: 'absolute',
      right: clamp(2, 25),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    });

    styleElement(this._renderer, this.list, {
      listStyleType: 'disc',
      display: 'flex',
      flexDirection: 'column',
      gap: clamp(0.4, 4),
      marginLeft: clamp(1, 2),
    });

    styleElement(this._renderer, this.text, {
      position: 'absolute',
      bottom: clamp(1.2, 20),
      left: clamp(1, 8),
      display: 'flex',
      flexDirection: 'column',
      gap: clamp(0.4, 3),
    });

    styleElement(this._renderer, this.brain, {
      position: 'absolute',
      top: clamp(4, 0),
      width: '75%',
    });
  }
}
