import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Student } from '../interfaces/student.interface';
import { NotificationClass } from 'src/app/shared/classes/notification.class';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { omit, isEmpty, assign, has, trim } from 'lodash';
import { ArchiveDialogComponent } from './archive-dialog/archive.dialog';
import { CalendarDialogComponent } from './calendar-dialog/calendar.dialog';
import { SAVE_MODE } from './calendar-dialog/consts/save-mode';
import { RoleService } from 'src/app/services/roles/role.service';
import { Languages } from 'src/app/shared/models/languages.model';
import { defaultLanguages, defaultLanguageLabel } from './student-constants/default-languages.constant';
import { get, compact } from 'lodash';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { EditTypes } from '../../users/components/edit-types/edit-types';
import { Theme } from '../../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { Token } from 'src/app/shared/models';
import { GameTag } from '../../demo/constants/demo-constants';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';

declare let cloudinary: any;

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
})
export class StudentProfileComponent extends NotificationClass implements OnInit {
  public formGroup: UntypedFormGroup;
  public archivedStudents: Student[];
  public clientId: string;
  public level: string;
  public themes: Theme[] = [];
  public tokens: Token[] = [];
  public languages: Languages[];
  public allowedThemes: boolean;
  public loading = true;
  public calendar = false;
  public selectedStudent;
  public studentAgenda: any;
  public studentHasTokens = false;
  public editUser: {
    id: string;
    patronId: string;
    archived?: boolean;
  };

  public categories: Category[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _rest: RestAPIService,
    private _router: Router,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _roles: RoleService,
    private themeService: ThemeService,
    private globalConfigHelper: GlobalConfigurationHelper,
  ) {
    super(_snackBar);
    this.formGroup = this._createUserFormGroup();
    const queryParams = this._router['currentUrlTree']['queryParams'];
    this.selectedStudent = queryParams;
    if (isEmpty(queryParams) === false) {
      this.SetUser(queryParams);

      const form = {
        fullname: !has(queryParams, 'fullname') ? this.GetFullName(queryParams) : queryParams.fullname,
        nickname: queryParams.nickname,
        birthdate: queryParams.birthdate,
        gender: queryParams.gender,
        image: queryParams.image,
        language: queryParams.language,
        alertOnSessionEnd: queryParams.alertOnSessionEnd === 'true',
        theme: queryParams.theme ? queryParams.themeLabel : get(this.themeService.getClassicTheme(), 'label.en_ca'),
        enableHomeAccess: this.isHomeAccessEnabled(queryParams),
      };

      this.formGroup.setValue(
        omit(form, [
          'id',
          'patronId',
          'givenName',
          'familyName',
          'createdBy',
          'agenda',
          'themeLabel',
          'tokens',
          'archived',
          'certificateIssued',
        ]),
      );
    }

    this.clientId = this._activatedRoute.snapshot.paramMap.get('client');

    this.SetDefaultTheme();
  }

  public SetDefaultTheme() {
    if (!this.formGroup.get('theme').value) {
      this.formGroup.get('theme').setValue('Neuralign - Full program');
    }
  }

  public GetFullName(queryParams) {
    const givenName = get(queryParams, 'givenName', '');
    const familyName = get(queryParams, 'familyName', '');

    const fullName = `${givenName} ${familyName}`.trim();
    queryParams.fullname = fullName;

    return fullName;
  }

  public isHomeAccessEnabled(queryParams): boolean {
    const tokens = get(queryParams, 'tokens', []);

    this.studentHasTokens = tokens.length > 0;
    const filteredTokens = compact(tokens);
    this.tokens = filteredTokens.map((t) => JSON.parse(t));

    if (this.studentHasTokens) {
      const associatedToken = this.tokens[0];

      return get(associatedToken, 'allowCompleteAtHome', false);
    }

    return get(queryParams, 'enableHomeAccess', false) === 'true';
  }

  public async AllowHomeAccess() {
    const allowRemoteAccess = this.formGroup.get('enableHomeAccess').value;
    const name = this.formGroup.get('fullname').value;
    const tokens: Token[] = this.tokens;

    if (tokens.length > 0) {
      for (const token of tokens) {
        token.allowCompleteAtHome = allowRemoteAccess;
        await this._rest.put('token/' + token.id, { token });
      }
    }

    this._snackBar.open(`Remote access ${allowRemoteAccess ? 'granted' : 'removed'} for the student ${name}`, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  public SetUser(queryParams) {
    this.editUser = {
      id: queryParams.id,
      patronId: queryParams.patronId,
    };
  }

  public async ngOnInit() {
    this.level = this._roles?.access?.level;
    await this.loadThemes();
    await this.loadLanguages();
    await this.findStudentAgenda();
    this.categories = await this.globalConfigHelper.getCategories();
    await this.checkNameInput();
    this.loading = false;
  }

  public returnThemeLabel(theme: Theme) {
    const studentTheme = this.themes.find((t) => t.id === theme.id);

    if (studentTheme) {
      const label = theme.label[this.formGroup.get('language').value];

      return label ? label : theme.label[defaultLanguageLabel];
    }
  }

  public redirectToNeuralign() {
    this._router.navigate(['program/' + this.editUser.id + '/Neuralign']);
  }

  public checkFormDisabled(): boolean {
    const controls = this.formGroup.controls;
    return Object.keys(controls).some((key: string) => {
      return controls[key].touched && !controls[key].valid;
    });
  }

  public async save(): Promise<void> {
    // split the name in two parts
    const fullname = this.formGroup.getRawValue()['fullname'];

    const fullNameSplit = fullname.split(' ');
    const name = fullNameSplit.shift();
    const familyName = fullNameSplit.join(' ');

    const formValue = assign(
      this.formGroup.getRawValue(),
      { givenName: name, familyName },
      { createdBy: get(this._roles, 'user.id', '') },
    );

    try {
      if (isEmpty(this.editUser)) {
        const student: Student = formValue;

        if (this.studentAgenda && !isEmpty(this.studentAgenda)) {
          student.agenda = this.studentAgenda;
        }

        const theme = this.findTheme(this.formGroup.value.theme);

        if (theme) {
          student.theme = theme.id;
        }

        await this._rest.post(
          'student/' + (this.clientId || 'self'),
          {
            student,
          },
          { msg: 'Could not post student' },
        );
        this.notify('Student created succefully!');
      } else {
        const student: Student = assign(formValue, this.editUser);

        if (this.studentAgenda && !isEmpty(this.studentAgenda)) {
          student.agenda = this.studentAgenda;
        }

        const theme = this.findTheme(this.formGroup.value.theme);

        if (theme) {
          student.theme = theme.id;
        }

        await this._rest.put(
          `student/${this.editUser.id}`,
          {
            student,
          },
          { msg: 'Could not put student' },
        );
        this.notify('Student updated succefully!');
      }

      if (this.clientId) {
        if (!isEmpty(this.editUser)) {
          this._router.navigate(['/users'], { queryParams: { userId: this.clientId, type: EditTypes.Students } });
        } else {
          this._router.navigate(['/users']);
        }
      } else {
        this._router.navigate(['/students']);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  public async openArchive(): Promise<void> {
    await this._loadArchivedStudents();
    const dialogRef = this._dialog.open(ArchiveDialogComponent, {
      width: '500px',
      data: this.archivedStudents,
    });
    dialogRef.afterClosed().subscribe((selectedStudent: Student) => {
      if (selectedStudent) {
        this._fillDataOfArchivedStudent(selectedStudent);

        this.editUser = {
          id: selectedStudent.id,
          patronId: selectedStudent.patronId,
          archived: false,
        };
      }
    });
  }

  private _fillDataOfArchivedStudent(student: Student) {
    this.formGroup.get('fullname').setValue(student.givenName + ' ' + student.familyName);
    this.formGroup.get('nickname').setValue(student.nickname);
    this.formGroup.get('language').setValue(student.language);
    this.formGroup.get('birthdate').setValue(student.birthdate);
    this.formGroup.get('gender').setValue(student.gender);
    if (student.image && student.image.trim() !== '') {
      this.formGroup.get('image').setValue(student.image);
    }
  }

  public uploadToCloudinary(): void {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.formGroup.get('image').setValue(secure_url || url);
      } else if (error && error.message !== 'User closed widget') {
        this.notify('There was an error while uploading the image, please try again later');
      }
    });
  }

  private _createUserFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      fullname: new UntypedFormControl('', [Validators.required]),
      nickname: new UntypedFormControl(''),
      theme: new UntypedFormControl('', [Validators.required]),
      language: new UntypedFormControl('en_ca'),
      birthdate: new UntypedFormControl('', [Validators.required]),
      gender: new UntypedFormControl(''),
      image: new UntypedFormControl('./assets/img/StudentImagePlaceholder.png'),
      alertOnSessionEnd: new UntypedFormControl(false),
      enableHomeAccess: new UntypedFormControl(false),
    });
  }

  private async _loadArchivedStudents(): Promise<void> {
    try {
      const response = await this._rest.get('patron/' + this.clientId + '/students?archived=true', {
        msg: 'Could not get patron/:clientId/students?archived=true.',
      });
      this.archivedStudents = response.students;
      // eslint-disable-next-line no-empty
    } catch {}
  }

  public async loadThemes() {
    const themes = await this.themeService.getEnabledThemes();

    this.themes = themes;
  }

  public async loadLanguages() {
    this.languages = await this._rest.get('languages', { msg: 'Could not get languages.' });
  }

  public returnLanguages() {
    const selectedTheme = this.findTheme(this.formGroup.value.theme);
    const languagesArray = [];

    if (selectedTheme && selectedTheme.languages) {
      for (const lang of selectedTheme.languages) {
        const language = this.languages.find((l) => l.id === lang.languageId);

        if (language) {
          languagesArray.push(language);
        }
      }

      return languagesArray.length > 0 ? languagesArray : defaultLanguages;
    } else {
      return defaultLanguages;
    }
  }

  public resetLanguage(theme) {
    const language = this.formGroup.value.language;

    const checkEnabledLanguages: boolean = theme.languages.find((l) => l.languageCode === language);

    if (!checkEnabledLanguages) {
      const selectedLanguage = theme.languages[0];

      if (selectedLanguage) {
        this.formGroup.get('language').setValue(selectedLanguage.languageCode);
      } else {
        this.formGroup.get('language').setValue('en_ca');
      }
    }
  }

  public checkIfEnabled(language): boolean {
    const themeLabel = this.formGroup.value.theme;

    if (!this.themes) {
      return true;
    }

    const theme = this.findTheme(themeLabel);

    return theme ? theme.enabled[language] : true;
  }

  public async findStudentAgenda() {
    if (this.selectedStudent.id) {
      const response = await this._rest.get('student/' + this.selectedStudent.id, {
        msg: 'Could not get student.',
      });
      if (response) {
        this.studentAgenda = response.student.agenda;
      }
    }
  }

  public openOptionsDialog() {
    const calendarDialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: this.studentAgenda,
        saveMode: SAVE_MODE.SAVE,
      },
      panelClass: 'modal-border',
    });

    calendarDialog.afterClosed().subscribe((agenda) => {
      if (!agenda) {
        return;
      }

      this.studentAgenda = agenda;
    });
  }

  public openStudentAgenda() {
    const calendarDialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: this.studentAgenda,
        saveMode: SAVE_MODE.SAVE,
      },
      panelClass: 'modal-border',
    });

    calendarDialog.afterClosed().subscribe((agenda) => {
      if (!agenda) {
        return;
      }

      this.studentAgenda = agenda;
    });
  }

  public checkIfHasSavedAgenda() {
    if (!this.selectedStudent) {
      return false;
    }

    return this.studentAgenda || this.selectedStudent.agenda ? true : false;
  }

  public findTheme(themeLabel) {
    const theme = this.themes.find((t) => t.label.en_ca === themeLabel);

    if (theme) {
      return theme;
    }
  }

  public async checkNameInput() {
    if (!isEmpty(this.selectedStudent)) {
      const student: Student = await this._rest.get('student/' + this.selectedStudent.id);

      if (get(student, 'tokens', []).length === 0 || !get(this.selectedStudent, 'id', undefined)) {
        return;
      }

      const studentToken: Token = student.tokens[0];
      const CTprogress = await this._rest.get(
        '/student/progress/' + this.selectedStudent.id + '/' + GameTag.CognitiveTherapy,
      );
      const CTcategory = this.categories.find(
        (c) => trim(c.name.toLowerCase()) === trim(GameTag.CognitiveTherapy.toLowerCase()),
      );

      if (!studentToken.allowProgressRerun || !CTcategory || !CTprogress) {
        return;
      }

      const CTprogressAmount = get(CTprogress, 'progress', []).length;

      if (CTprogressAmount >= CTcategory.maxSessions) {
        this.formGroup.get('fullname').disable();
        this.formGroup.get('nickname').disable();
      }
    }
  }
}
