import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private logEvents = true;

  // EVENTS --

  // Next Lesson
  private isReadyForNextLessonEvent = new BehaviorSubject<boolean>(false);
  public isReadyForNextLessonEvent$ = this.isReadyForNextLessonEvent.asObservable();
  public emitIsReadyForNextLesson(bool: boolean) {
    this.dev('User is ready for next lesson', bool);
    this.isReadyForNextLessonEvent.next(bool);
  }

  // HELPERS --

  private dev(message: string, value: any) {
    if (isDevMode() && this.logEvents) {
      console.log(
        `%cEVENT - ${message} :`,
        'background: #ACE; color: #111; padding: 0.25rem 0.5rem; border-radius: 3px; margin: 0.5rem;',
        value,
      );
    }
  }
}
