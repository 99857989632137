<ng-container *ngIf="currentSection$ | async as section">
  <div
    class="container"
    [ngSwitch]="section.type"
  >
    <ng-container *ngSwitchCase="'text'">
      <display-text-lesson
        [content]="section.content"
        [@fade]
      ></display-text-lesson>
    </ng-container>

    <ng-container *ngSwitchCase="'quiz'">
      <display-quiz-lesson
        class="d-flex justify-content-center align-items-center h-100"
        [content]="section.content"
        [@fade]
      ></display-quiz-lesson>
    </ng-container>

    <ng-container *ngSwitchCase="'slides'">
      <display-slides-lesson
        class="h-100"
        [@fade]
      ></display-slides-lesson>
    </ng-container>

    <ng-container *ngSwitchCase="'video'">
      <display-video-lesson
        class="videoContainer"
        [content]="section.content"
        [@fade]
      ></display-video-lesson>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <!-- <display-start-lesson [content]="lesson.content"></display-start-lesson> -->
    </ng-container>
  </div>
</ng-container>
