import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const SIDE_MENU_LOCAL_STORAGE_KEY = 'userSideMenuPreference';

export type SideMenuSection =
  | 'none'
  | 'users'
  | 'admin'
  | 'configuration'
  | 'programs'
  | 'demo'
  | 'tutorials'
  | 'documents'
  | 'stripe';

export interface SideMenuState {
  isSideMenuOpen: boolean;
  sections: Record<string, boolean>;
}

const defaultSideMenuState: SideMenuState = {
  isSideMenuOpen: false,
  sections: {},
};

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  private state: BehaviorSubject<SideMenuState> = new BehaviorSubject(defaultSideMenuState);
  public sideMenu$: Observable<SideMenuState> = this.state.asObservable();

  constructor() {
    this.loadSideMenuUserPreference();
  }

  // Local Storage --

  private loadSideMenuUserPreference(): void {
    let userPreference = null;
    userPreference = JSON.parse(localStorage.getItem(SIDE_MENU_LOCAL_STORAGE_KEY));

    if (userPreference === null) {
      userPreference = defaultSideMenuState;
      window.localStorage.setItem(SIDE_MENU_LOCAL_STORAGE_KEY, JSON.stringify(userPreference));
      this.state.next(userPreference);
    } else {
      this.state.next(userPreference);
    }
  }

  private saveSideMenuUserPreference() {
    window.localStorage.setItem(SIDE_MENU_LOCAL_STORAGE_KEY, JSON.stringify(this.state.value));
  }

  // Toggles --

  public toggleSideMenu(): void {
    const newState = this.state.value;

    if (this.state.value.isSideMenuOpen) {
      newState.isSideMenuOpen = false;
    } else {
      newState.isSideMenuOpen = true;
    }

    this.state.next(newState);
    this.saveSideMenuUserPreference();
  }

  public openSideMenu() {
    const newState = this.state.value;
    newState.isSideMenuOpen = true;
    this.state.next(newState);
    this.saveSideMenuUserPreference();
  }

  private updateSection(section: SideMenuSection) {
    const newState = this.state.value;

    if (this.state.value.sections[section]) {
      newState.sections[section] = false;
      this.state.next(newState);
      this.saveSideMenuUserPreference();
    } else {
      newState.sections[section] = true;
      this.state.next(newState);
      this.saveSideMenuUserPreference();
    }
  }

  public toggleSection(section: string): void {
    switch (section) {
      case 'Users':
      case 'Students':
        this.updateSection('users');
        break;
      case 'Admin':
        this.updateSection('admin');
        break;
      case 'Configuration':
        this.updateSection('configuration');
        break;
      case 'Programs':
        this.updateSection('programs');
        break;
      case 'Demo':
        this.updateSection('demo');
        break;
      case 'Tutorials':
        this.updateSection('tutorials');
        break;
      case 'Documents':
        this.updateSection('documents');
        break;
      case 'Stripe':
        this.updateSection('stripe');
        break;
      default:
        console.error('Could not toggle section:', section);
        return;
    }
  }
}
