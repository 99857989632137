import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlidesService } from 'src/app/pages/courses/services/slides/slides.service';

@Component({
  selector: 'next-slide-button',
  templateUrl: './next-slide-button.component.html',
  styleUrls: ['./next-slide-button.component.scss'],
})
export class NextSlideButtonComponent implements OnInit, OnDestroy {
  currentSlide$ = this._slides.currentSlide$;

  constructor(private _slides: SlidesService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  handleClick() {
    this._slides.selectNextSlide();
  }
}
