<p
  class="credits-per-month"
  *ngIf="type === SubscriptionPeriod.yearly"
>
  {{ bundle?.metadata?.tokens / 12 }} Credit/month
</p>
<p
  class="credits-per-month"
  *ngIf="type === SubscriptionPeriod.monthly"
>
  {{ bundle?.metadata?.tokens }} Credit/month
</p>

<p class="price">
  <ng-container *ngIf="type === SubscriptionPeriod.yearly">
    {{ bundle?.currency.toUpperCase() }}$
    <span class="amount">{{ ceil(bundle?.unit_amount / 100 / 12) }}</span
    >/credit
  </ng-container>
  <ng-container *ngIf="type === SubscriptionPeriod.monthly">
    {{ bundle?.currency.toUpperCase() }}$
    <span class="amount">{{ bundle?.unit_amount / 100 }}</span
    >/month</ng-container
  >
</p>

<p class="total-credits">{{ bundle?.metadata?.tokens }} credits</p>

<p class="total">
  <ng-container *ngIf="type === SubscriptionPeriod.yearly">
    Billed annually at {{ bundle?.currency }}${{ bundle?.unit_amount / 100 }}
  </ng-container>
  <ng-container *ngIf="type === SubscriptionPeriod.monthly">
    {{ bundle?.metadata?.tokens }} Credit/month for 12 months
  </ng-container>
</p>

<button
  class="buy-now"
  (click)="addToCart(bundle)"
>
  Buy Now
</button>
