<div class="mainContainer">
  <div class="header col-12 p-3 text-center">Regions</div>

  <div class="title mt-3">Region Info</div>

  <div class="info my-3 mx-auto">
    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> Region </span>
      </div>

      <div class="mx-2">
        <input
          required
          name="name"
          class="text-center basicInput"
          [(ngModel)]="region.name"
          autocomplete="off"
          placeholder="Region name"
        />
      </div>
    </div>

    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> Email </span>
      </div>

      <div class="mx-2">
        <input
          required
          name="name"
          class="text-center basicInput"
          [(ngModel)]="region.email"
          autocomplete="off"
          placeholder="Region name"
        />
      </div>
    </div>

    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> Iso 2 </span>
      </div>

      <div class="mx-2">
        <input
          required
          name="iso2"
          class="text-center basicInput"
          [(ngModel)]="region.iso2"
          placeholder="Region iso2"
        />
      </div>
    </div>

    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> Iso 3 </span>
      </div>

      <div class="mx-2">
        <input
          required
          name="iso3"
          class="text-center basicInput"
          [(ngModel)]="region.iso3"
          autocomplete="off"
          placeholder="Region iso3"
        />
      </div>
    </div>

    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> Organization </span>
      </div>

      <div class="mx-2">
        <select
          required
          class="basicInput basicSelectable"
          [(ngModel)]="region.orgId"
          aria-placeholder="Select the organization"
        >
          <option
            class="disabledOption"
            value=""
            disabled
            selected
          >
            Choose the organization
          </option>
          <option
            class="p-2"
            *ngFor="let org of data.orgList"
            [value]="org.id"
            autocomplete="off"
          >
            {{ getOrgName(org) }}
          </option>
        </select>
      </div>
    </div>

    <div class="buttons my-4 d-flex justify-content-center align-items-center">
      <app-simple-button
        class="mx-2"
        text="CONFIRM"
        (click)="confirm()"
      ></app-simple-button>

      <app-simple-button
        class="mx-2"
        text="CANCEL"
        (click)="closeDialog()"
      ></app-simple-button>
    </div>
  </div>
</div>
