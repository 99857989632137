import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest/rest-api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdatesHelperService {
  private unreadUpdates = new BehaviorSubject(0);
  public $unreadUpdates = this.unreadUpdates.asObservable();

  constructor(private _rest: RestAPIService) {
    this.getUnreadUpdatesCount();
  }

  public async getUnreadUpdatesCount() {
    const count = await this._rest.get('updates/newUpdates', {
      msg: 'Could not get updates/newUpdates',
    });

    this.unreadUpdates.next(count);
  }
}
