'use strict';

exports.__esModule = true;
exports.getUsernameValidation = getUsernameValidation;
exports.setUsername = setUsername;
exports.usernameLooksLikeEmail = usernameLooksLikeEmail;
var _index = require('./index');
var _email = require('./email');
var _database = require('../connection/database');
var _trim = require('trim');
var _trim2 = _interopRequireDefault(_trim);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var DEFAULT_CONNECTION_VALIDATION = {
  username: {
    min: 1,
    max: 15
  }
};
var regExp = /^[a-zA-Z0-9_+\-.!#\$\^`~@']*$/;
function validateUsername(str, validateFormat) {
  var settings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_CONNECTION_VALIDATION.username;
  var strictValidation = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  // If the connection does not have validation settings, it should only check if the field is empty.
  // validateFormat overrides this logic to disable validation on login (login should never validate format)
  if (!validateFormat || settings == null) {
    return (0, _trim2.default)(str).length > 0;
  }
  var lowercased = (0, _trim2.default)(str.toLowerCase());

  // check min value matched
  if (lowercased.length < settings.min) {
    return false;
  }

  // check max value matched
  if (lowercased.length > settings.max) {
    return false;
  }

  // check if user name is an email
  if (strictValidation && (0, _email.validateEmail)(str) === true) {
    return false;
  }

  // check allowed characters matched
  var result = regExp.exec(lowercased);
  return !!(result && result[0]);
}
function getUsernameValidation(m) {
  var usernameValidation = (0, _database.databaseConnection)(m).getIn(['validation', 'username']);
  return usernameValidation ? usernameValidation.toJS() : null;
}
function setUsername(m, str) {
  var usernameStyle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'username';
  var validateUsernameFormat = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var strictValidation = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var usernameValidation = validateUsernameFormat ? getUsernameValidation(m) : null;
  var validator = function validator(value) {
    switch (usernameStyle) {
      case 'email':
        return (0, _email.validateEmail)(value);
      case 'username':
        return validateUsername(value, validateUsernameFormat, usernameValidation, strictValidation);
      default:
        return usernameLooksLikeEmail(value) ? (0, _email.validateEmail)(value) : validateUsername(value, validateUsernameFormat, usernameValidation, strictValidation);
    }
  };
  return (0, _index.setField)(m, 'username', str, validator);
}
function usernameLooksLikeEmail(str) {
  return str.indexOf('@') > -1 && str.indexOf('.') > -1;
}