import { includes } from 'lodash';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ProgressManagerHelper } from 'src/app/shared/helpers/progress-manager';
import { Course, CourseProgress } from '../configuration-pages/interfaces/global-config.interfaces';

enum AllowedUsers {
  organizations = 'organizations',
  b2c = 'b2c',
  clients = 'clients',
}

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  public courses = [];
  public loading = true;
  public courseList = true;
  public overview = false;
  public learning = false;
  public selectedCourse: Course;
  public selectedCourseProgress: CourseProgress;
  public courseProgressList: CourseProgress[] = [];
  public isOrganization: boolean;
  public isB2c: boolean;
  public isClient: boolean;
  private _progressManager: ProgressManagerHelper;

  constructor(public auth: AuthService, public rest: RestAPIService, public roleService: RoleService) {
    this._progressManager = new ProgressManagerHelper(rest);
  }

  async ngOnInit() {
    this.isOrganization = this.roleService.isOrgOwner() || this.roleService.isPortalOwner();
    this.isB2c = this.roleService.isB2C();
    this.isClient = this.roleService.isOrgClient();

    await this.getCourses();
    await this.getUserProgress();
    this.loading = false;
  }

  public async getCourses() {
    const userType = this.isOrganization
      ? AllowedUsers.organizations
      : this.isB2c
      ? AllowedUsers.b2c
      : AllowedUsers.clients;
    const courses = await this.rest.get('/courses/avaliableCourses/' + userType);

    this.courses = courses;
  }

  public async getUserProgress() {
    const user = await this.auth.getUser();

    this.courseProgressList = user.courseProgresses || [];
  }

  public getLessonsAmount(course: Course): number {
    if (!course.lessons) {
      return 0;
    }

    return course.lessons.length;
  }

  public backToPortal() {
    this.courseList = true;
    this.learning = false;
    this.overview = false;

    this.ngOnInit();
  }

  public toggleOverview(course: Course) {
    this.overview = true;
    this.courseList = false;
    this.learning = false;
    this.selectedCourse = course;
  }

  public courseIsStarted(courseId: string): boolean {
    const progress = this.courseProgressList.find((p) => p.courseId === courseId);
    return progress !== undefined;
  }

  public async toggleLearning(course: Course) {
    this.loading = true;
    const user = await this.auth.getUser();
    this.selectedCourseProgress = this.courseProgressList.find((p) => p.courseId === course.id);

    if (!this.courseIsStarted(course.id)) {
      this.selectedCourseProgress = await this.rest.post(
        'courseProgress/accountId/' + user.id,
        this._progressManager.createNewProgress(course.id, course.lessons),
      );
    }

    this.selectedCourse = course;
    this.loading = false;
    this.overview = false;
    this.courseList = false;
    this.learning = true;
  }

  public getCourseProgression(course: Course) {
    const courseProgress = this.courseProgressList.find((c) => c.courseId === course.id);

    if (!courseProgress) {
      return;
    }

    const courseLessonsIdList = course.lessons.map((l) => l._id);
    const completedLessons = courseProgress.lessons.filter(
      (p) => p.completed && includes(courseLessonsIdList, p.lessonId),
    );

    return ((completedLessons.length / course.lessons.length) * 100).toFixed(0) + '%';
  }

  public async saveUserProgress(progress: CourseProgress) {
    await this.rest.put('courseProgress/update', progress);
  }

  public getCourse(id: string) {
    const course = this.courses.find((course) => id === course.id);

    if (!course) {
      return 'no course found';
    } else {
      return course;
    }
  }
}
