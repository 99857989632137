import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LessonsService } from 'src/app/pages/courses/services/lessons/lessons.service';
import { fadeAnimation } from 'src/app/pages/courses/utils/fade.animation';

@Component({
  selector: 'main-view-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  animations: fadeAnimation,
})
export class DisplayComponent {
  currentSection$: Observable<any> = this._lessons.currentSection$;

  constructor(private _lessons: LessonsService) {}
}
