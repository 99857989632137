import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ActivatedRoute } from '@angular/router';
import { GameStundentDTO, QueryData, SessionConfigs } from 'src/app/shared/components/game/game.interface';
import { GameComponent } from 'src/app/shared/components/game/game.component';
import { classic } from 'src/app/shared/consts/themeLabels';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-demo-game',
  templateUrl: './demo-game.component.html',
  styleUrls: ['./demo-game.component.scss'],
})
export class DemoGameComponent implements OnInit {
  public queryData: QueryData;
  public gameStudentDTO: GameStundentDTO;
  public overwriteSessionDetails: SessionConfigs;
  public lesson: string;
  public theme: string;
  public lang: string;
  public level: string;
  public loading = true;
  public showGame = false;

  @ViewChild(GameComponent) game: GameComponent;
  @ViewChild('iframe') iframe: ElementRef;

  constructor(public _rest: RestAPIService, private _activatedRoute: ActivatedRoute) {}

  async ngOnInit() {
    this.lesson = this._activatedRoute.snapshot.paramMap.get('lesson');
    this.theme = this._activatedRoute.snapshot.paramMap.get('theme');
    this.lang = this._activatedRoute.snapshot.paramMap.get('lang');
    this.level = this._activatedRoute.snapshot.paramMap.get('level');

    const categoryType = this.lesson;
    const session = 0;
    const level = this.level || '0';
    const category = categoryType;
    const lang = this.lang || 'en_ca';
    const week = 0;
    const theme = this.theme || 'classic';

    this.gameStudentDTO = {
      name: 'Demo Student',
      language: lang,
      level: Number(this.level),
      program: 1,
      progress: session,
    };

    this.queryData = {
      week: `l${week}`,
      level,
      category,
      session,
      lang,
      theme,
    };

    this.overwriteSessionDetails = await this.requestDemoSessionDetails(theme, lang, category, level, session);

    this.showGame = true;
    this.loading = false;
  }

  public async requestDemoSessionDetails(
    theme: string,
    lang: string,
    category: string,
    level: string,
    session: number,
  ) {
    const sessionDetails = await this._rest.get(
      `/demos/demosConfigs/language/${lang}/theme/${theme || classic}/level/${level}/category/${category}`,
      {
        params: new HttpParams()
          .set('week', this.queryData.week)
          .set('level', level)
          .set('category', category)
          .set('session', session.toString()),
        msg: 'Could not get session',
      },
    );

    return sessionDetails;
  }
}
