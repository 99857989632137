<section #definitionBox>
  <div #dyslexiaBox>
    <h2
      montserrat
      thin
      largeSize
    >
      Dys.lex.i.a /dəsˈleksēə/
    </h2>
    <ul
      #list
      montserrat
      mediumSize
      zeroMargin
    >
      <li>
        A neurotype shared by 1 in 5 <br />
        people worldwide, and by 4 in 5 <br />
        people with learning disabilities.
      </li>
      <li>
        It makes it hard to read, spell <br />
        write, or do math.
      </li>
      <li>
        It can cause feelings of anxiety, <br />
        anger, depression, or low self-<br />
        esteem.
      </li>
    </ul>
  </div>
</section>

<section #einstein>
  <div #text>
    <p
      montserrat
      thin
      mediumSize
      white
    >
      Dyslexics often excel in outside-the-box <br />
      creative and critical thinking. Some of the <br />
      world's greatest celebrities, leaders, artists,<br />
      and entrepreneurs are Dyslexic.³
    </p>
    <p
      montserrat
      thin
      mediumSize
      white
    >
      With the right approach and support, you can<br />
      thrive!
    </p>
  </div>

  <div>
    <img
      src="assets/img/slides/einstein.jpg"
      alt=""
      width="100%"
    />
  </div>
</section>

<div #brain>
  <img
    src="assets/img/brains/colourful-brain-left.webp"
    alt=""
    width="100%"
  />
</div>
