import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkBook, WorkSheet, utils, write, read } from 'xlsx';

import { UsersHelperService } from '../../../users-helper.service';
import { Client, UserType } from '../../../../../shared/interfaces';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { EXCEL_EXTENSION, EXCEL_TYPE } from 'src/app/services/excel/excel.constants';
import { validateStudentImportData } from 'src/app/shared/helpers/validateStudentImportData.function';
import { isExcelDateSerial } from 'src/app/shared/helpers/isExcelDateSerial.function';
import { dateToJSDate } from 'src/app/shared/helpers/dateToJSDate.function';
@Injectable({
  providedIn: 'root',
})
export class ClientsListService {
  clients: Client[];

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _rest: RestAPIService,
    private _logger: LoggerService,
    private _snackBar: MatSnackBar,
    private _usersHelperService: UsersHelperService,
  ) {}

  public async getClients(options?: { refresh: boolean }): Promise<Client[]> {
    try {
      if (options?.refresh || !this.clients) {
        const response = await this._rest.get(`/organization/self/patrons`);
        this.clients = response.patrons.map((client: Client) => {
          return {
            ...client,
            name: this._usersHelperService.transformUserName(client),
            status: this._usersHelperService.getUserStatus(client.emailVerified),
            type: UserType.Client,
          };
        });
      }
      return this.clients;
    } catch (err) {
      this._logger.error(err);
    }
  }

  public addClientStudent(id: string): void {
    this._router.navigate([`/students/profile/${id}`]);
  }

  public editClient(client: Client): void {
    localStorage.setItem('LS_OrgEditingUser', JSON.stringify(client));
    this._router.navigate(['/users/manage/' + client.id]);
  }

  public resendClientVerificationEmail(client: Client): void {
    const userName = client.fullname ? client.fullname : client.name;

    this._rest
      .get('account/email/verify/resend/' + client.accountId, {
        msg: 'Could not get account/email/resend/:clientAccountId.',
      })
      .then(() => {
        this._snackBar.open(`Verification email has been sent to ${userName}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
      .catch(() => {
        this._snackBar.open('E-mail not found', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public async deleteClient(id: string): Promise<void> {
    try {
      await this._rest.put('patron/archive/' + id, {}, { msg: 'Could not put patron/archive.' });
    } catch (err) {
      this._logger.error(err);
    }
  }
  public downloadStudentFile() {
    const workbook: WorkBook = { SheetNames: [], Sheets: {} };

    const students = [
      {
        givenName: '',
        familyName: '',
        nickname: '',
        language: 'en_ca',
        birthdate: '',
        gender: '',
      },
    ];

    workbook.SheetNames.push('students');
    workbook.Sheets['students'] = utils.json_to_sheet(students);

    const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });

    saveAs(blob, `importStudentsFileExample-${new Date().toISOString()}${EXCEL_EXTENSION}`);
  }

  public importStudentsForFile(e: any, client: Client): void {
    if (!e.target.files.length) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'No file selected. Please select a file to import.',
      });
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event: any) => {
      const students = [];
      const fileContent = event.target.result;
      const workbook: WorkBook = read(fileContent, { type: 'binary' });
      const sheetNames = workbook.SheetNames;

      sheetNames.map((sheetName) => {
        const sheet: WorkSheet = workbook.Sheets[sheetName];
        const sheetData = utils.sheet_to_json(sheet);

        const checkIfExistsError = validateStudentImportData(sheetData as Student[]);
        if (checkIfExistsError) {
          this._dialog.open(AlertDialogComponent, {
            width: '400px',
            data: checkIfExistsError.error,
          });
          return;
        }

        if (sheetName === 'students' && !checkIfExistsError) {
          for (const data of sheetData) {
            if (isExcelDateSerial(data['birthdate'])) {
              data['birthdate'] = dateToJSDate(Number(data['birthdate']));
            }
            if (!data['nickname']) {
              data['nickname'] = data['givenName'].split(' ')[0];
            }
            if (data['gender'] === 'M') {
              data['gender'] = 'Male';
            }
            if (data['gender'] === 'F') {
              data['gender'] = 'Female';
            }
            if (data['gender'] === 'O') {
              data['gender'] = 'Other';
            }
            data['image'] = '';
            data['archived'] = false;
            students.push(data);
          }

          const promiseList = [];
          for (const student of students) {
            promiseList.push(this._rest.post('/student/' + client.id, { student: student }));
          }

          Promise.all(promiseList)
            .then(() => {
              this._dialog.open(AlertDialogComponent, {
                width: '400px',
                data: 'All users have been created',
              });
            })
            .catch((error) => {
              this._dialog.open(AlertDialogComponent, {
                width: '400px',
                data: error.message,
              });
            });
        }
      });
    };
    reader.readAsBinaryString(file);
  }
}
