import { Component, OnInit } from '@angular/core';
import { faImages, faLanguage, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sortBy, isEmpty, get } from 'lodash';
import { Organization } from 'src/app/shared/models';
import { Language, Theme } from './components/themes/interfaces/themes.interface';
import { Category, GlobalConfiguration } from '../interfaces/global-config.interfaces';
import { Router } from '@angular/router';

enum ContentOptions {
  Themes = 'themes',
  Languages = 'languages',
  Categories = 'categories',
}

@Component({
  selector: 'app-content-configurations',
  templateUrl: './content-configurations.component.html',
  styleUrls: ['../configurations.style.scss'],
})
export class ContentConfigurationsComponent implements OnInit {
  public readonly themes: IconDefinition = faImages;
  public readonly languageIcon: IconDefinition = faLanguage;
  public readonly categoriesIcon: IconDefinition = faGamepad;

  public globalThemes: Theme[];
  public loading: boolean;
  public selectedOption;
  public organizationList: Organization[];
  public languagesList: Language[];
  public categoriesList: Category[];
  public globalConfigurations: GlobalConfiguration;
  public categoryToSelect = null;

  constructor(private _rest: RestAPIService, protected _snackBar: MatSnackBar, private _router: Router) {}

  async ngOnInit() {
    const queryParams = this._router['currentUrlTree']['queryParams'];

    this.setLoading(true);
    await this.getGlobalConfiguration();
    await this.getOrganizationsList();
    await this.getLanguagesList();

    this.selectedOption = ContentOptions.Themes;

    if (!isEmpty(queryParams)) {
      const optionToSelect = queryParams.option;

      switch (optionToSelect) {
        case ContentOptions.Categories: {
          this.selectedOption = ContentOptions.Categories;
          const courseId = queryParams.courseId;

          if (courseId) {
            this.categoryToSelect = this.categoriesList.find((c) => c.courseId == courseId);
          }

          break;
        }
        case ContentOptions.Languages: {
          this.selectedOption = ContentOptions.Languages;
          break;
        }
        case ContentOptions.Themes: {
          this.selectedOption = ContentOptions.Themes;
          break;
        }
        default:
          break;
      }
    }

    this.setLoading(false);
  }

  public async getGlobalConfiguration() {
    this.globalConfigurations = await this._rest.get('globalConfig/config', { msg: 'Could not get global config.' });
    this.categoriesList = this.globalConfigurations.categories;
    await this.getThemes();
  }

  private async getThemes() {
    this.globalThemes = await this._rest.get('themes/readThemes', { msg: 'Could not get themes' });
  }

  public async getOrganizationsList() {
    const users = await this._rest.get('organization/all', {
      msg: 'Could not get organization list.',
    });

    this.organizationList = sortBy(users, (u) => get(u, 'organization.name')).filter(
      (u) => !isEmpty(get(u, 'organization.name')),
    );
  }

  public async getLanguagesList() {
    this.languagesList = await this._rest.get('/languages', {
      msg: 'Could not get languages list.',
    });
  }

  public reset() {
    this.ngOnInit();
  }

  public setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
