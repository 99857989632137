import { MetadataDialogComponent } from './metadata/metadata.dialog';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmDialogComponent } from './confirm/confirm.dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { TokenAmountModalComponent } from './add-token-amount/add-token-amount.dialog';
import { SelectStudentDialogComponent } from './select-student/select-student.dialog';
import { MaterialComponentsModule } from '../modules/material-components.module';
import { AlertDialogComponent } from './alert/alert.dialog';
import { SelectClientDialogComponent } from './select-client/select-client.dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ExportDataDialogComponent } from './export-data/export-data.dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { OrgFinderComponent } from './org-finder-dialog/org-finder.dialog.component';
import { LayoutComponentsModule } from '../components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GraphPreview } from './graph-preview/graph-preview.component';
import { FullSessionResultComponent } from './full-session-result/full-session-result';
import { AuthErrorDialog } from './auth-error/auth-error.dialog';
import { CookiesConsentDialog } from './cookies-consent/cookies-consent.dialog';
import { RouterModule } from '@angular/router';
import { ManageCookiesDialog } from './manage-cookies/manage-cookies.dialog';
import { MessageDialogComponent } from './message/message.dialog';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    SelectStudentDialogComponent,
    SelectClientDialogComponent,
    TokenAmountModalComponent,
    MetadataDialogComponent,
    ExportDataDialogComponent,
    ErrorDialogComponent,
    OrgFinderComponent,
    GraphPreview,
    FullSessionResultComponent,
    AuthErrorDialog,
    CookiesConsentDialog,
    ManageCookiesDialog,
    MessageDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatDialogModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    RouterModule,
  ],
  exports: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    SelectStudentDialogComponent,
    SelectClientDialogComponent,
    TokenAmountModalComponent,
    MetadataDialogComponent,
    ExportDataDialogComponent,
    GraphPreview,
    CookiesConsentDialog,
  ],
})
export class DialogsModule {}
