import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-button',
  template: ` <button
    class="simple-button"
    [ngClass]="{ accent: themeStyle === 'accent', outline: themeStyle === 'outline', smaller: smaller }"
    type="button"
    [disabled]="disabled"
    [ngStyle]="{ 'background-color': colour }"
  >
    <fa-icon
      *ngIf="iconType"
      [icon]="iconType"
    ></fa-icon>
    {{ text }}
  </button>`,
  styleUrls: ['./simple-button.component.scss'],
})
export class SimpleButtonComponent {
  @Input() readonly themeStyle: string = 'primary';
  @Input() readonly text: string = '';
  @Input() readonly disabled: boolean = false;
  @Input() readonly smaller?: boolean = false;
  @Input() readonly iconType: string = '';
  @Input() colour: string;
}
