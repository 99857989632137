<div
  #sidemenu
  class="sidemenu"
  *ngIf="!shouldHideMenu()"
>
  <app-side-menu></app-side-menu>
</div>

<app-header *ngIf="showHeader"></app-header>

<app-breadcrumb
  *ngIf="breadcrumbs && showBreadcrumbs"
  [menuItems]="breadcrumbs"
></app-breadcrumb>

<main>
  <ng-content></ng-content>
</main>
