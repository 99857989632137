import { User } from '../models';

export interface Invoice {
  paid: boolean;
  status: string;
}

export interface StripeInvoice {}

export interface PaymentPlan {
  cost: number;
  currency: string;
  id: string;
  subscriptionItemId: string;
  currentPeriodEnd: number;
  lastInvoice: Invoice;
  user: User;
  endDate: number;
  startDate: number;
  completedPayments: number;
  interval: string;
  studentsIncluded: number;
  card: StripeCard;
}

export interface StripeCard {
  brand: string;
  last4: string;
}

export interface StripeEvent {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

export interface StripeProduct {
  id: string;
  active: boolean;
  name: string;
  description?: string;
  default_price_data?: {
    currency: string;
    unit_amount: number;
    recurring: {
      interval: StripePriceInterval;
    };
  };
  metadata?: {
    tax_code?: string;
  };
  object?: string;
  currency?: string;
  unit_amount?: number;
  interval?: StripePriceInterval;
  default_price?: string;
}

export interface StripeTax {
  id?: string;
  active?: boolean;
  country?: string;
  created?: number;
  description?: string;
  display_name?: string;
  efective_percentage?: number;
  inclusive?: boolean;
  jurisdiction?: string;
  jurisdiction_level?: null;
  metadata?: unknown;
  percentage?: number;
  state?: string;
  tax_type?: string;
  object?: string;
}
export interface StripePrice {
  id: string;
  type: StripePriceType;
  currency: string;
  unit_amount: number;
  recurring: {
    interval: StripePriceInterval;
  };
  product: string;
  active: boolean;
  nickname?: string;
  metadata?: {
    tokens?: number;
    first_purchase?: 'yes' | 'no';
  };
  object?: string;
}

export enum StripePriceType {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

export enum StripePriceInterval {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

export interface StripeRedirect {
  redirectUrl: string;
}

export enum StripeBundleNickname {
  singleCredit = 'Single Credit',
}

export interface StripePriceModal {
  id: string;
  priceType?: string;
  priceCurrency?: string;
  priceUnitAmount?: number;
  priceActive?: boolean;
  priceProduct?: string;
  priceNickname?: string;
  priceQuantityTokens?: number;
  priceInterval?: StripePriceInterval;
  priceFirstPurchase?: string;
}

export interface StripeProductModal {
  id: string;
  productName?: string;
  productDescription?: string;
  productActive?: boolean;
  productPriceCurrency?: string;
  productPriceUnitAmount?: number;
  productDefaultPrice?: string;
  productPriceInterval?: StripePriceInterval;
  productTax?: string;
}

export interface StripeTaxModal {
  id: string;
  taxType?: string;
  taxCountry?: string;
  taxState?: string;
  taxPercentage?: number;
  taxInclusive?: boolean;
  taxActive?: boolean;
}

export interface StripeDataModal {
  type?: string;
  service: string;
  routeApi: string;
  typeHttp: string;
  stripeDataModal?: {
    data?: StripeProductModal | StripePriceModal | StripeTaxModal;
  };
}

export enum StripeFirstPurchaseType {
  YES = 'yes',
  NO = 'no',
}

export enum StripeDecimalMultiplier {
  Hundred = 100,
}

export enum StripeHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum StripeCountryWithState {
  US = 'us',
  CA = 'ca',
}
