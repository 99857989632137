<div class="actions">
  <app-simple-button
    *ngIf="!userHasStripeAccount"
    text="Create Your Stripe Account"
    (click)="generateStripeAccount()"
    [matTooltip]="
      'In order to receive your payment it is necessary to create a linked account with our partner Stripe'
    "
  ></app-simple-button>

  <div
    *ngIf="userHasStripeAccount"
    class="toggle-b2c-signup"
    (click)="toggleB2C()"
  >
    <mat-checkbox
      *ngIf="!isLoadingActivationStatusChange"
      type="checkbox"
      name="activate-b2c"
      [checked]="b2cActivationStatus"
    ></mat-checkbox>
    <mat-spinner
      *ngIf="isLoadingActivationStatusChange"
      [diameter]="16"
    ></mat-spinner>
    <span>Activate B2C</span>
  </div>

  <app-simple-button
    text="Adjust Campaign Pricing"
    (click)="adjustB2CPrice()"
  ></app-simple-button>

  <app-simple-button
    text="Select Enabled Themes"
    (click)="selectEnabledThemes()"
  ></app-simple-button>
</div>

<users-layout-two-tables>
  <div leftTable>
    <users-b2c-list
      (onGetStudents)="handleGetStudents($event)"
    ></users-b2c-list>
  </div>
  <div rightTable><users-b2c-students-list></users-b2c-students-list></div>
</users-layout-two-tables>
