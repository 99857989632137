import { isEmpty, get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { TutorialsService } from 'src/app/services/tutorials/tutorials.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { PrivacyScreenService } from 'src/app/services/privacy-screen/privacy-screen.service';

import { User } from 'src/app/shared/models';
import { TutorialComponent } from 'src/app/shared/models/tutorial.model';

import { TabsComponent } from './components/tabs/tabs.component';
import { MessageService } from 'src/app/services/messages/message.service';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { PromotionalPriceComponent } from 'src/app/shared/dialogs/promotional-price/promotional-price.component';

import { fadeIn } from './styles/animations';
import { tutorialVideos } from 'src/app/shared/consts/global-constants';
import { UserTypes } from '../configuration-pages/course-configurations/components/learning-management-system/utils/course-utils';

export type Menu = 'clients' | 'b2c' | 'organizations' | 'managersAndAdmins' | 'new-user' | 'privacy';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [fadeIn],
})
export class UsersComponent implements OnInit, AfterViewInit {
  // Templates
  template: Menu;

  // Tutorial
  public tutorial: TutorialComponent;
  isSingle = true;
  showTutorial = false;
  public onCompleteTutorial: () => void;
  public canAdvanceSubdomain = 'canadvance';

  // B2C Activation
  b2cAllowSignupStatus;
  userHasStripeAccount;
  isLoadingActivationStatusChange;

  public readonly question: IconDefinition = faQuestionCircle;

  public tutorialVideos = tutorialVideos;

  public tutorialUrl = this.tutorialVideos[UserTypes.ORGANIZATIONS];

  @ViewChild(TabsComponent) tabsComponent: TabsComponent;
  @ViewChild(ImportExportComponent) exportComponent: ImportExportComponent;

  constructor(
    //Private variables
    private dialog: MatDialog,
    private _auth: AuthService,
    private _roles: RoleService,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private confirm: ConfirmationService,
    private tutorialService: TutorialsService,
    private _changeDetector: ChangeDetectorRef,
    private _privacyScreen: PrivacyScreenService,

    //Public variables
    public messageService: MessageService,
  ) {}

  async ngOnInit() {
    this.initTemplate();
    await this.setupTutorial();
    await this.initUserHasStripeAccount();
    await this.initB2cActivationStatus();
    await this.messageService.initUserMessage();
  }

  ngAfterViewInit() {
    // make no tab active when privacy screen is on
    if (this.template === 'privacy') {
      this.tabsComponent.activeTab = '';
    }

    // avoid Error NG0100: ExpressionChangedAfterItHasBeenCheckedError
    this._changeDetector.detectChanges();
  }

  public async handlePromotionalPriceDialog() {
    const user = this._auth.getOrgAcc();

    if (
      !get(user, 'organization.isOutsider', false) &&
      get(user, 'organization') &&
      this._auth.promotionalDialogViewed === false &&
      get(user, 'organization.subdomain', '') != this.canAdvanceSubdomain
    ) {
      const dialog = this.dialog.open(PromotionalPriceComponent, {
        data: {
          bodyImage: 'assets/img/Beat the heat sale.png',
          basicPrice: 750,
          studentsIncluded: 3,
          promotionalPrice: 250,
        },
        panelClass: 'modal-border',
        width: '800px',
      });

      dialog.afterClosed().subscribe(() => {
        this._auth.promotionalDialogViewed = true;
      });
    }
  }

  public async handleToggleB2CSignup() {
    this.isLoadingActivationStatusChange = true;
    try {
      this._rest
        .put(
          'organization/self',
          {
            organization: { allowSignup: !this.b2cAllowSignupStatus },
          },
          { msg: 'Could not put organization.' },
        )
        .then(async () => {
          if (this._roles.isOrgAdmin()) {
            const { organization } = await this._rest.get('organization/self', {
              msg: 'Could not get organization.',
            });

            this.b2cAllowSignupStatus = organization.allowSignup;
          } else {
            this._auth.getUser(true).then((user) => (this.b2cAllowSignupStatus = user.organization.allowSignup));
          }
        })
        .then(() => {
          setTimeout(() => {
            this.isLoadingActivationStatusChange = false;
          }, 2000); // add a small timeout to avoid flicker
        });
    } catch (err) {
      this.isLoadingActivationStatusChange = false;
      this._snackBar.open(`${err.error.message}`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public switchMenu(menu: Menu) {
    if (menu === 'privacy') {
      this._privacyScreen.showPrivacyScreen = true;
    }

    this._privacyScreen.turnOff();
    this.template = menu;
  }

  public handleAddNewUser() {
    this.template = 'new-user';
    this.tabsComponent.activeTab = '';
  }

  private initTemplate() {
    if (this._privacyScreen.showPrivacyScreen) {
      this.template = 'privacy';
    } else {
      this.template = 'clients';
    }
  }

  public async setupTutorial() {
    const user = await this.authService.getUser();

    if (!user) {
      return;
    }

    const { lastLogin } = user;

    if (isEmpty(lastLogin)) {
      await this.openTutorialVideo(user);
    } else if (
      get(user, 'organization.acceptedTerms', false) === false &&
      get(user, 'organization.isOutsider', false) === true
    ) {
      this.acceptTerms(user);
    }
  }

  public acceptTerms(user: User) {
    this.confirm
      .createConfirmation(
        'Terms of Service',
        'You must agree with our &nbsp; <a target="_blank" href="/terms-of-service"> Terms of Service </a>  to continue with access to your LSWorks portal',
        'I agree',
        undefined,
        '400px',
        true,
      )
      .then(async () => {
        user.organization.acceptedTerms = true;
        await this._rest.put(
          'organization/self',
          {
            organization: user.organization,
          },
          { msg: 'Could not put organization' },
        );
      });
  }

  public async openTutorialVideo(user: User) {
    await this.tutorialService.setupTutorial(user);
  }

  public async openWelcomeVideo() {
    const user = await this.authService.getUser();

    await this.tutorialService.setupTutorial(user);
  }

  private async initUserHasStripeAccount() {
    const { hasChargesEnabled } = await this._rest.get('account/checkStripeSetup', {
      msg: 'Could not get account.',
    });
    this.userHasStripeAccount = hasChargesEnabled;
  }

  private async initB2cActivationStatus() {
    const user = await this._auth.getUser(true);
    this.b2cAllowSignupStatus = get(user, 'organization.allowSignup', false);
  }
}
