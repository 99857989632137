<ng-container *ngIf="currentLesson$ | async as lesson">
  <div class="grid-container">
    <div class="col-one"><prev-lesson-button></prev-lesson-button></div>
    <div class="col-two">
      <ng-container *ngIf="hasSlideSections()">
        <prev-slide-button></prev-slide-button>
        <next-slide-button></next-slide-button>
      </ng-container>
      <ng-container *ngIf="hasQuizSections()">
        <prev-quiz-button></prev-quiz-button>
        <next-quiz-button></next-quiz-button>
      </ng-container>
    </div>
    <div class="col-three"><next-section-button></next-section-button></div>
  </div>
</ng-container>
