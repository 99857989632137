import { Component, Input } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
//import { CreateOrUpdateModalComponent } from 'src/app/pages/stripe/create-update-modal/create-or-update-modal.component';
import { ProductStripeModalComponent } from 'src/app/pages/stripe/modals/product-stripe-modal/product-stripe-modal.component';
import { PriceStripeModalComponent } from 'src/app/pages/stripe/modals/price-stripe-modal/price-stripe-modal.component';
import { TaxStripeModalComponent } from 'src/app/pages/stripe/modals/tax-stripe-modal/tax-stripe-modal.component';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent {
  @Input() service: string;
  @Input() routeApi: string;
  @Input() typeHttp: string;
  @Input() enable: boolean;

  public faPlus = faPlus;

  private readonly MODAL_WIDTH = '600px';

  constructor(public dialog: MatDialog) {}

  public handleClick() {
    const modalComponent = this.getModalComponent(this.service);
    if (modalComponent) {
      this.dialog.open(modalComponent as any, {
        width: this.MODAL_WIDTH,
        disableClose: true,
        data: {
          service: this.service,
          routeApi: this.routeApi,
          typeHttp: this.typeHttp,
        },
      });
    }
  }

  private getModalComponent(service: string) {
    switch (service) {
      case 'product':
        return ProductStripeModalComponent;
      case 'price':
        return PriceStripeModalComponent;
      case 'tax_rate':
        return TaxStripeModalComponent;
      default:
        return null;
    }
  }
}
