import { NgModule } from '@angular/core';
import { ControlsComponent } from './controls.component';
import { NextSectionButtonComponent } from './next-section-button/next-section-button.component';
import { NextSlideButtonComponent } from './next-slide-button/next-slide-button.component';
import { PrevSlideButtonComponent } from './prev-slide-button/prev-slide-button.component';
import { CommonModule } from '@angular/common';
import { NextQuizQuestionButtonComponent } from './next-quiz-question-button/next-quiz-question-button.component';
import { PrevSectionButtonComponent } from './prev-section-button/prev-section-button.component';
import { PrevQuizQuestionButtonComponent } from './prev-quiz-question-button/prev-quiz-question-button.component';

@NgModule({
  declarations: [
    ControlsComponent,
    NextSectionButtonComponent,
    NextSlideButtonComponent,
    PrevSectionButtonComponent,
    PrevSlideButtonComponent,
    NextQuizQuestionButtonComponent,
    PrevQuizQuestionButtonComponent,
  ],
  imports: [CommonModule],
  exports: [ControlsComponent],
})
export class ControlsModule {}
