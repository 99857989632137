import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';

@Component({
  selector: 'prev-quiz-button',
  templateUrl: './prev-quiz-question-button.component.html',
  styleUrls: ['./prev-quiz-question-button.component.scss'],
})
export class PrevQuizQuestionButtonComponent implements OnInit, OnDestroy {
  public currentQuestion$ = this._quiz.currentQuizQuestion$;

  isFirstQuestion = true;
  isFirstQuestionSub: Subscription;

  isQuizComplete = false;
  isQuizCompleteSub: Subscription;

  constructor(private _quiz: QuizService) {}

  ngOnInit(): void {
    this.isFirstQuestionSub = this._quiz.currentQuizQuestionCompleteEvent.subscribe(
      (bool) => (this.isFirstQuestion = bool),
    );

    this.isQuizCompleteSub = this._quiz.currentQuizCompleteEvent.subscribe((bool) => (this.isQuizComplete = bool));
  }

  ngOnDestroy() {
    this.isFirstQuestionSub.unsubscribe();
    this.isQuizCompleteSub.unsubscribe();
  }

  handleClick() {
    this._quiz.prevQuizQuestion();
  }
}
