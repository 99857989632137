import { Component, OnInit } from '@angular/core';
import { faTrash, faEye, faEdit } from '@fortawesome/free-solid-svg-icons';

import { RestAPIService } from '../../services/rest/rest-api.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ConfirmationService } from '../../services/confirmation/confirmation.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdatesComponent as ModalUpdates } from '../../shared/components/updates/updates.component';
import { UpdatesHelperService } from 'src/app/services/utils/updates-helper.service';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss'],
})
export class UpdatesComponent implements OnInit {
  readonly trash: IconDefinition = faTrash;
  readonly eye: IconDefinition = faEye;
  readonly edit: IconDefinition = faEdit;
  updatesList = [];
  isPortalAdmin = false;

  constructor(
    private _restService: RestAPIService,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private router: Router,
    private confirm: ConfirmationService,
    private _updatesHelper: UpdatesHelperService,
  ) {}

  async ngOnInit() {
    this.isPortalAdmin = await this._authService.isPortalAdmin();
    await this.loadData();
  }

  confirmDelete(update) {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this Update?', 'Yes', 'No')
      .then(async () => {
        this.deleteUpdate(update);
      })
      .then(() => {
        this._updatesHelper.getUnreadUpdatesCount();
      });
  }

  async deleteUpdate(update) {
    await this._restService.delete('updates/' + update.id, { msg: 'Could not delete updates.' });
    await this.loadData();
  }

  async loadData() {
    const updatesList = await this._restService.get('updates', {
      msg: 'Could not get all updates.',
    });
    this.updatesList = updatesList;
  }

  async goToLink(selected) {
    if (this.isPortalAdmin) {
      this.router.navigate(['/admin/updates/', selected.id]);
    } else {
      this._dialog.open(ModalUpdates, {
        width: '650px',
        data: {
          updatesList: [],
          canGoBack: false,
          selected,
        },
      });
    }
  }

  getClass(type) {
    if (type.toLowerCase() === 'new feature') {
      return 'feature';
    }

    return type.toLowerCase();
  }
}
