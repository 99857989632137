<div class="side-menu">
  <!-- Sidemenu Toggle -->
  <div
    data-test="side-menu-toggle"
    class="toggle-side-menu"
    (click)="toggleSideMenu()"
  >
    <fa-icon
      *ngIf="!isOpen"
      [icon]="rightArrows"
    ></fa-icon>
    <fa-icon
      *ngIf="isOpen"
      [icon]="leftArrows"
    ></fa-icon>
  </div>

  <!-- Dashboard -->
  <div
    *ngIf="isOrgOwner || isOrgAdmin || isOrgManager"
    class="no-dropdown"
    (click)="goto('/dashboard')"
    [matTooltip]="!isOpen ? 'Dashboard' : ''"
    [matTooltipPosition]="'right'"
    [routerLink]="'/dashboard'"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fa-icon [icon]="dashboardIcon"></fa-icon>
    <span
      class="title"
      *ngIf="isOpen"
      >Dashboard</span
    >
  </div>

  <!-- Stripe -->
  <app-side-menu-section
    *ngIf="isPortalAdmin"
    [title]="'Stripe'"
    [icon]="financeIcon"
    [tooltip]="'Stripe'"
    [sectionKey]="'stripe'"
  >
    <a
      (click)="goto('/stripe/products')"
      [routerLink]="'/stripe/products'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Products
    </a>
    <a
      (click)="goto('/stripe/prices')"
      [routerLink]="'/stripe/prices'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Prices
    </a>
    <a
      (click)="goto('/stripe/taxes')"
      [routerLink]="'/stripe/taxes'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Taxes
    </a>
  </app-side-menu-section>

  <!-- Users -->

  <!-- Patrons -->
  <app-side-menu-section
    *ngIf="isPatron"
    [title]="'Students'"
    [icon]="usersIcon"
    [tooltip]="'Students'"
    [sectionKey]="'users'"
  >
    <a
      (click)="goto('/users')"
      [routerLink]="'/users'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Active Students</a
    >
    <a
      *ngIf="isB2CPortal"
      (click)="goto('/students/profile')"
      [routerLink]="'/students/profile'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Add New</a
    >
  </app-side-menu-section>

  <!-- Not Patrons -->
  <app-side-menu-section
    *ngIf="!isPatron"
    [title]="'Users'"
    [icon]="usersIcon"
    [tooltip]="'Users'"
    [sectionKey]="'users'"
  >
    <a
      (click)="goto('/users')"
      [routerLink]="'/users'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Active Users</a
    >
    <a
      *ngIf="!isOrgClient() || checkOrgAdminManager()"
      (click)="goto('/users/manage/0')"
      [routerLink]="'/users/manage/0'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Add New</a
    >
  </app-side-menu-section>

  <!-- Admin -->

  <app-side-menu-section
    *ngIf="isPortalAdmin"
    [title]="'Admin'"
    [icon]="adminIcon"
    [tooltip]="'Admin'"
    [sectionKey]="'admin'"
  >
    <a
      (click)="goto('/admin/dashboard')"
      [routerLink]="'/admin/dashboard'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Dashboard
    </a>
    <a
      (click)="goto('/admin/updates')"
      [routerLink]="'/admin/updates'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Updates</a
    >
    <a
      (click)="goto('/users/coupon')"
      [routerLink]="'/users/coupon'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Coupon</a
    >
  </app-side-menu-section>

  <!-- Configurations -->

  <app-side-menu-section
    *ngIf="isPortalAdmin"
    [title]="'Configuration'"
    [icon]="toolBox"
    [tooltip]="'Configuration'"
    [sectionKey]="'configuration'"
  >
    <a
      (click)="goto('/global-configurations')"
      [routerLink]="'/global-configurations'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Global</a
    >
    <a
      (click)="goto('/content-configurations')"
      [routerLink]="'/content-configurations'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Content</a
    >
    <a
      (click)="goto('/region-configurations')"
      [routerLink]="'/region-configurations'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Region</a
    >
    <a
      (click)="goto('/courses-configurations')"
      [routerLink]="'/courses-configurations'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Courses</a
    >
  </app-side-menu-section>

  <!-- Programs -->

  <app-side-menu-section
    data-test="side-menu-programs-dropdown"
    [title]="'Programs'"
    [icon]="gamepadIcon"
    [tooltip]="'Programs'"
    [sectionKey]="'programs'"
  >
    <a
      *ngIf="isPurchaseEnabled()"
      (click)="goto('/programs-pricing')"
      [routerLink]="'/programs-pricing'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Purchase Programs</a
    >
    <a
      *ngIf="
        isPurchaseEnabled() && !isB2CPortal && !isOrgAdmin && !isOrgManager
      "
      (click)="goto('/subscription-control-center')"
      [routerLink]="'/subscription-control-center'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Manage Subscriptions</a
    >
    <a
      *ngIf="isPurchaseEnabled() && haveAccessToB2cPaymentsDashboard"
      (click)="goto('/installment-payments-control-center')"
      [routerLink]="'/installment-payments-control-center'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Manage Payments</a
    >
    <a
      data-test="neuralign-programs"
      (click)="listStudents()"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Neuralign</a
    >
  </app-side-menu-section>

  <!-- Demo -->

  <app-side-menu-section
    [title]="'Demo'"
    [icon]="desktopIcon"
    [tooltip]="'Demos'"
    [sectionKey]="'demo'"
  >
    <a
      (click)="goto('/demo')"
      [routerLink]="'/demo'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >All Demos</a
    >
    <a
      (click)="
        goto('/demo', false, {
          game: 'gumball',
          theme: 'classic',
          lang: 'en_ca',
          level: 0
        })
      "
      [routerLink]="'/demo'"
      [queryParams]="{ game: 'gumball' }"
      [routerLinkActive]="'active'"
      >Mouse training</a
    >
  </app-side-menu-section>

  <!-- Tutorials -->

  <app-side-menu-section
    [title]="'Tutorials'"
    [icon]="questionIcon"
    [tooltip]="'Tutorials'"
    [sectionKey]="'tutorials'"
  >
    <a
      *ngIf="haveAccessToLms && shouldEnableLms()"
      (click)="goto('/courses')"
      [routerLink]="'/courses'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      {{
        isB2CPortal || isPatron
          ? 'Coaching Certification'
          : 'Provider Certification'
      }}
    </a>
    <a (click)="openTutorials()">Welcome video</a>
    <a (click)="activateBeforeYouStart()">Before You Start Neuralign</a>
  </app-side-menu-section>

  <!-- Documents -->

  <app-side-menu-section
    *ngIf="(!isOrgClient() && !isPatron) || checkOrgAdminManager()"
    [title]="'Documents'"
    [icon]="bookIcon"
    [tooltip]="'Documents'"
    [sectionKey]="'documents'"
  >
    <a
      (click)="goto('/documents')"
      [routerLink]="'/documents'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >All Documents</a
    >
  </app-side-menu-section>

  <!-- Just for B2C -->
  <app-side-menu-section
    *ngIf="isB2CPortal"
    [title]="'Documents'"
    [icon]="bookIcon"
    [tooltip]="'Documents'"
    [sectionKey]="'documents'"
  >
    <a
      (click)="goto('/documents')"
      [routerLink]="'/documents'"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Practice Book</a
    >
  </app-side-menu-section>

  <debug-helper></debug-helper>
</div>
