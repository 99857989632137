import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { RoleService } from 'src/app/services/roles/role.service';
import { HeaderComponent } from '../../components/header/header.component';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SideMenuService } from '../../components/side-menu/side-menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  @Input() breadcrumbs;
  @Input() showHeader = true;
  @Input() showBreadcrumbs = true;

  isSideMenuOpen = false;
  sideMenuSub: Subscription;

  @ViewChild('sidemenu') sidemenu: ElementRef;

  constructor(private _auth: AuthService, private _roles: RoleService, private _sideMenuServce: SideMenuService) {}

  ngAfterViewInit(): void {
    this.sideMenuSub = this._sideMenuServce.sideMenu$.subscribe((data) => {
      // b2c clients without any tokens purchased do not see the
      // sidemenu so there are cases where this.sidemenu is undefined.
      if (this.sidemenu) {
        if (data.isSideMenuOpen) {
          this.sidemenu.nativeElement.setAttribute('style', 'width: 20rem');
          this.isSideMenuOpen = true;
        } else {
          this.sidemenu.nativeElement.setAttribute('style', 'width: 6rem');
          this.isSideMenuOpen = false;
        }
      }
    });
  }

  ngOnDestroy() {
    this.sideMenuSub.unsubscribe();
  }

  public shouldHideMenu(): boolean {
    const firstTokenPurhased = get(this._auth.user, 'firstTokenPurchased', false);
    const pendingRegistration = get(this._auth.user, 'pendingRegistration', false);

    return (!firstTokenPurhased && !this._roles.isOrgClient()) || pendingRegistration;
  }
}
