<header>
  <img
    src="assets/img/NeurAlign text logo.png"
    width="100%"
  />
</header>

<video
  [src]="data | safeUrl"
  allow="encrypted-media"
  allowfullscreen
  controls
  width="100%"
></video>

<button (click)="closeModal()">x</button>
