import { Component, OnInit } from '@angular/core';
import { LessonsService } from 'src/app/pages/courses/services/lessons/lessons.service';

@Component({
  selector: 'main-view-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public currentSection$ = this._lessons.currentSection$;

  constructor(private _lessons: LessonsService) {}

  ngOnInit(): void {}
}
