import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from '../../../services/rest/rest-api.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Router } from '@angular/router';
import { UpdatesHelperService } from 'src/app/services/utils/updates-helper.service';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss'],
})
export class UpdatesComponent implements OnInit {
  readonly arrowBack: IconDefinition = faArrowLeft;
  updatesList = [];
  canGoBack = true;
  selected = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<any>,
    private _restAPI: RestAPIService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private _updatesHelper: UpdatesHelperService,
  ) {}

  ngOnInit() {
    this.updatesList = this.data.updatesList;
    this.selected = this.data.selected || null;
    this.canGoBack = this.data.canGoBack;

    if (this.selected && !this.canGoBack) {
      this.clickRow(this.selected);
    }
  }

  limitText(val) {
    const newText = val.replace(/<[^>]*>?/gm, '');
    return newText.substring(0, 50) + (newText.length > 50 ? ' ...' : '');
  }

  goBack() {
    this.updatesList = this.updatesList.filter((item) => item.id !== this.selected.id);
    this.selected = null;
  }

  async clickRow(item) {
    this.selected = { ...item };
    this.selected.description = this.parseHtml(this.selected.description);

    await this._restAPI.put(`updates/markUpdate/${item.id}`, {}, { msg: 'Could not put updates/markUpdate/:itemId.' });

    this._updatesHelper.getUnreadUpdatesCount();
  }

  parseHtml(val) {
    const youtubeUrl = val.match(/\[youtube](.*?)\[\/youtube]/gi)?.pop();

    if (youtubeUrl) {
      const fullUrl = youtubeUrl.replace(/\[(\/|)youtube]/g, '');
      let videoId = fullUrl.substring(fullUrl.indexOf('v=') + 2);
      if (videoId.indexOf('&') !== -1) {
        videoId = videoId.substring(0, videoId.indexOf('&') + 1);
      }

      const embed = `<iframe width="100%" height="270" src="https://www.youtube.com/embed/${videoId}?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      val = val.replace(youtubeUrl, embed);
    }

    val = val.replace('\n', '<br />');
    return this._sanitizer.bypassSecurityTrustHtml(val);
  }

  getClass(type) {
    if (type.toLowerCase() === 'new feature') {
      return 'feature';
    }

    return type.toLowerCase();
  }

  goToLink() {
    this.router.navigate(['/admin/updates/']);
    this._dialogRef.close();
  }

  public async markAllRead() {
    await this._restAPI.put(
      `updates/markAllUpdates`,
      { ids: this.updatesList.map((u) => u.id) },
      { msg: 'Could not mark all updates.' },
    );

    this._updatesHelper.getUnreadUpdatesCount();

    this._dialogRef.close();
  }
}
