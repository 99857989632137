import { RoleService } from 'src/app/services/roles/role.service';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import {
  faBars,
  faUsers,
  faQuestion,
  IconDefinition,
  faGamepad,
  faSpinner,
  faChartLine,
  faDesktop,
  faUserCircle,
  faBook,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectStudentDialogComponent } from '../../dialogs/select-student/select-student.dialog';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../../dialogs/alert/alert.dialog';
import { SelectClientDialogComponent } from '../../dialogs/select-client/select-client.dialog';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { SubscriptionRequestService } from 'src/app/services/portal/subscription-request.service';
import { Client } from '../../interfaces/Client.interface';
import { BeforeYouStartComponent } from '../before-you-start/before-you-start.component';
import { TutorialsService } from 'src/app/services/tutorials/tutorials.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SideMenuService } from './side-menu.service';
import { Subscription } from 'rxjs';
import { get } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { TokenService } from 'src/app/services/token/token.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Input() reload: boolean;

  public isOpen = false;
  private sideMenuSub: Subscription;

  @Output() hasBeenToggled = new EventEmitter<boolean>();

  public adminIcon: IconDefinition = faUserCircle;
  public barsIcon: IconDefinition = faBars;
  public usersIcon: IconDefinition = faUsers;
  public gamepadIcon: IconDefinition = faGamepad;
  public questionIcon: IconDefinition = faQuestion;
  public spinnerIcon: IconDefinition = faSpinner;
  public dashboardIcon: IconDefinition = faChartLine;
  public desktopIcon: IconDefinition = faDesktop;
  public bookIcon: IconDefinition = faBook;
  public rightArrows: IconDefinition = faAngleDoubleRight;
  public leftArrows: IconDefinition = faAngleDoubleLeft;
  public toolBox: IconDefinition = faToolbox;
  public financeIcon: IconDefinition = faChartLine;

  public isPatron = false;
  public isPortalAdmin = false;
  public isB2CProvider = false;
  public isB2CPortal = false;
  public isOrgOwner = false;
  public isOrgAdmin = false;
  public isOrgManager = false;
  public isReseller = false;
  public hasSubscriptions = false;
  public loadingStudents = false;
  public showBeforeYouStart = false;
  public haveAccessToLms = false;
  public haveAccessToB2cPaymentsDashboard = false;
  public org: any = 'loading';

  constructor(
    private _rest: RestAPIService,
    private _auth: AuthService,
    private _dialog: MatDialog,
    private _router: Router,
    private _roles: RoleService,
    private _logger: LoggerService,
    private _subscriptionHelper: SubscriptionRequestService,
    private _tutorialService: TutorialsService,
    private _sideMenuService: SideMenuService,
    private _snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private tokenService: TokenService,
  ) {}

  async ngOnInit() {
    this.org = await this._rest
      .get('organization/self', { msg: 'Could not find organization.' })
      .catch(() => (this.org = null));

    await this.whoAmI();

    this.sideMenuSub = this._sideMenuService.sideMenu$.subscribe((data) => {
      this.isOpen = data.isSideMenuOpen;
    });
  }

  ngOnDestroy() {
    if (this.sideMenuSub) {
      this.sideMenuSub.unsubscribe();
    }
  }

  private async whoAmI() {
    this.hasSubscriptions = await this.checkIfHasActiveSubscriptions();
    this.isB2CProvider = this._roles.isB2CProvider();
    this.isB2CPortal = this._roles.isB2CPortal();
    this.isPatron = this._roles.isPatron();
    this.isPortalAdmin = this._roles.isPortalOwner();
    this.isOrgAdmin = this._roles.isOrgAdmin();
    this.isOrgOwner = this._roles.isOrgOwner();
    this.isOrgManager = this._roles.isOrgManager();
    this.isReseller = this._roles.isReseller();
    this.haveAccessToLms = this._roles.haveAccessToLms();
    this.haveAccessToB2cPaymentsDashboard = this._roles.haveAccessToB2cPaymentsDashboard();
  }

  public toggleSideMenu() {
    this._sideMenuService.toggleSideMenu();
  }

  public shouldEnableLms(): boolean {
    if (!this.isB2CPortal && !this._roles.isOrganizationPortal()) {
      return true;
    }

    const tokens = this.tokenService.getTokens();

    return tokens.length > 0;
  }

  public isOrgClient(): boolean {
    if (!this._roles.access) {
      return true;
    }
    return (
      this._roles.access.level === 'B2B' &&
      (this._roles.access.role === 'Client' || this._roles.access.role === 'Organization Manager')
    );
  }

  public activateBeforeYouStart() {
    this.showBeforeYouStart = true;

    this._dialog.open(BeforeYouStartComponent, {
      width: '800px',
      panelClass: 'beforeYouStart',
    });
  }

  public isClientPortal() {
    if (!this._roles.access) {
      return true;
    }
    return this._roles.access.level === 'B2B' && this._roles.access.role === 'Client';
  }

  public checkOrgAdminManager(): boolean {
    if (this.isOrgAdmin || this.isOrgManager) {
      return true;
    } else {
      return false;
    }
  }

  public openStepByStep(programName) {
    this._router.navigate(['stepByStep/' + programName]);
  }

  public listStudents(): void {
    this.isPatron ? this._loadStudents() : this._loadClients();
  }

  private async _loadStudents(): Promise<void> {
    this.loadingService.ActivateLoading();

    if (this.loadingStudents) {
      return;
    }

    try {
      this.loadingStudents = true;
      const response = await this._rest.get('student/self', { msg: 'Could not find student.' });

      if (response) {
        const associatedStudents = response.students.filter(
          (student: Student) => student.programs && student.programs.length > 0,
        );
        this._checkStudentsList(associatedStudents);
      }
    } catch (error) {
      this._snackBar.open(
        'Could not get your students list, please check your internet connection and refresh the page',
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );
    } finally {
      this.loadingStudents = false;
      this.loadingService.DisableLoading();
    }
  }

  public toggleMenuSections(property: string) {
    this[property] = !this[property];
  }

  private async _loadClients(): Promise<void> {
    this.loadingService.ActivateLoading();

    try {
      const response = await this._rest.get('organization/self/patrons', {
        msg: 'Could not find organization/self/patrons.',
      });
      const clients = response.patrons;
      while (clients.find((p) => p.archived)) {
        clients.splice(clients.indexOf(clients.find((p) => p.archived)), 1);
      }

      if (clients.length === 0) {
        this._dialog.open(AlertDialogComponent, {
          width: '500px',
          data: 'No client has been added yet.',
        });
      } else {
        this._openSelectClientDialog(clients);
      }
    } catch (error) {
      this._snackBar.open(
        'Could not get your clients list, please check your internet connection and refresh the page',
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );
    } finally {
      this.loadingService.DisableLoading();
    }
  }

  private async _loadClientStudents(clientId: string): Promise<void> {
    try {
      const response = await this._rest.get('patron/' + clientId + '/students', {
        msg: 'Could not find patron/:clientId/students.',
      });
      this._checkStudentsList(response.students);
    } catch (error) {
      throw new Error(error.message);
    }

    this.loadingService.DisableLoading();
  }

  private _checkStudentsList(students: Student[]): void {
    switch (students.length) {
      case 0:
        this._dialog.open(AlertDialogComponent, {
          width: '500px',
          data: 'Programs must be purchased and assigned to students first.',
        });
        break;
      default:
        this._openSelectStudentDialog(students);
        break;
    }
  }

  private _openSelectStudentDialog(students: Student[]): void {
    const dialogRef = this._dialog.open(SelectStudentDialogComponent, {
      width: '500px',
      data: students,
      panelClass: 'modal-border',
    });

    dialogRef.afterClosed().subscribe((student: Student) => {
      if (student) {
        if (this.studentHasTokens(student)) {
          this._router.navigate(['program/' + student.id + '/Neuralign']);
        } else {
          this._dialog.open(AlertDialogComponent, {
            data: 'This student dont have associated tokens',
            panelClass: 'modal-border',
            width: '400px',
            height: '270px',
          });
        }
      }
    });
  }

  public studentHasTokens(student: Student): boolean {
    return student.tokens?.length > 0;
  }

  private _openSelectClientDialog(clients: Client[]): void {
    const dialogRef = this._dialog.open(SelectClientDialogComponent, {
      width: '500px',
      data: clients,
      panelClass: 'modal-border',
    });

    dialogRef.afterClosed().subscribe((client: Client) => {
      if (client) {
        this.loadingService.ActivateLoading();
        this._loadClientStudents(client.id);
      }
    });
  }

  public async openTutorials() {
    const user = await this._auth.getUser();
    this._tutorialService.setupTutorial(user);
  }

  public goto(path, newTab?, queryParams?): void {
    if (!this.reload && !newTab) {
      if (path === '/programs-pricing' && this.isOrgClient()) {
        return;
      }
      this._router.navigate([path], { queryParams });
    } else {
      this._logger.info(path);
      window.open(path, newTab ? '_blank' : '_self');
    }
  }

  public isPurchaseEnabled(): boolean {
    if ((this.isPatron && !this.isB2CPortal) || this.isOrgManager) {
      return false;
    } else if (this.isB2CPortal) {
      // if the B2C provider have a stripe account disabled we disable the purchase option
      if (get(this.org, 'organization')) {
        const organization = this.org.organization;
        return organization && !organization.disableStripeWarning;
      }
    } else {
      return true;
    }
  }

  public async checkIfHasActiveSubscriptions(): Promise<boolean> {
    try {
      const user = this._roles.user;
      if (!user.organization) {
        return false;
      }

      const checkForSubscriptions = await this._subscriptionHelper.checkIfUserHaveSubscriptions();

      return checkForSubscriptions;
    } catch (err) {
      this._logger.error(err);
    }
  }
}
