<div class="mainContainer">
  <ng-container *ngIf="currentSlide$ | async as slide">
    <div>
      <p class="text-center">
        Slide {{ slide.sequence }}/{{ slide.numberOfSlides }}
      </p>
    </div>

    <div class="d-flex justify-content-center align-items-start videoContainer">
      <img
        [src]="slide.url"
        width="100%"
      />
    </div>
  </ng-container>
</div>
