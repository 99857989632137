import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';

@Component({
  selector: 'next-quiz-button',
  templateUrl: './next-quiz-question-button.component.html',
  styleUrls: ['./next-quiz-question-button.component.scss'],
})
export class NextQuizQuestionButtonComponent implements OnInit {
  isQuestionComplete = false;
  isQuestionCompleteSub: Subscription;

  isQuizComplete = false;
  isQuizCompleteSub: Subscription;

  constructor(private _quiz: QuizService) {}

  ngOnInit(): void {
    this.isQuestionCompleteSub = this._quiz.currentQuizQuestionCompleteEvent.subscribe(
      (bool) => (this.isQuestionComplete = bool),
    );

    this.isQuestionCompleteSub = this._quiz.currentQuizCompleteEvent.subscribe((bool) => (this.isQuizComplete = bool));
  }
  ngOnDestroy() {
    this.isQuestionCompleteSub.unsubscribe();
  }

  handleClick() {
    this._quiz.nextQuizQuestion();
  }
}
