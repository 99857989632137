<section class="purchase">
  <h1>Ready to boost your reading journey?</h1>

  <p class="neuralign-is">
    Neuralign is the premium research-based cognitive therapy program for
    dyslexic and struggling readers, and people who are neurodivergent.
  </p>

  <div class="cards">
    <div
      *ngIf="!loading"
      class="bundleCards"
    >
      <div
        *ngFor="let bundle of priceBundles"
        [ngClass]="{
          card: priceBundles.length > 2,
          singleBundle: priceBundles.length <= 2
        }"
      >
        <div class="badge">
          <p
            *ngIf="!bundle.subscription"
            class="rounded-outline"
          >
            Best Value! Save {{ splitPaymentAdditionalCost * 100 }}%
          </p>
          <p
            *ngIf="bundle.subscription && bundle.paymentType === 'Subscription'"
          >
            &nbsp; Subscription
          </p>
        </div>

        <div class="header">
          <p>
            {{ bundle.title }}
          </p>
        </div>

        <div class="price">
          <span
            >{{ bundle.currency || 'CAD' }}{{ bundle.currencySymbol || '$' }}
          </span>
          <span class="number">{{ bundle.price | roundUp }}</span>
          <span> {{ bundle.subscription ? '/' + bundle.interval : '' }}</span>
        </div>

        <div
          class="subscription"
          *ngIf="
            bundle.subscription &&
            bundle.interval &&
            bundle.paymentType === 'SplittedPayment'
          "
        >
          For {{ bundle.subscriptionPeriod }} {{ bundle.interval }}s ({{
            bundle.currency
          }}{{ bundle.currencySymbol || '$'
          }}{{ bundle.price * bundle.subscriptionPeriod | roundUp }})
        </div>
        <div
          class="subscription"
          *ngIf="bundle.interval && !bundle.subscription"
        >
          {{ bundle.interval }}
        </div>

        <div>
          <app-simple-button
            text="Buy Now"
            smaller="true"
            themeStyle="accent"
            (click)="addToCart(bundle)"
          ></app-simple-button>
        </div>
      </div>
    </div>

    <div
      class="p-5 d-flex justify-content-center align-items-center w-100 loading"
      *ngIf="loading"
    >
      <fa-icon
        [icon]="spinnerIcon"
        [spin]="true"
      >
      </fa-icon>
    </div>
  </div>
</section>

<section class="testimonials">
  <h1>Why people like you choose Neuralign</h1>

  <div class="cards">
    <div
      class="card"
      *ngFor="let testimonial of testimonials"
    >
      <h3>{{ testimonial.name }}</h3>
      <p>{{ testimonial.quote }}</p>
      <div class="stars">
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
      </div>
    </div>
  </div>
</section>
