<ng-container *ngIf="messages.length < 2">
  <ng-container *ngFor="let message of messages; let i = index">
    <div class="text-center">
      <div class="header">Neuralign</div>
      <img
        class="img"
        [src]="message.image"
        alt="Image"
      />
      <div class="content">{{message.text}}</div>
      <div class="group-button">
        <button (click)="goToCourse(message)">Go to course</button>
        <button
          class="button-close"
          (click)="close(message)"
        >
          Close
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Multiple messages -->
<ng-container *ngIf="messages.length > 1">
  <div class="carousel">
    <ng-container *ngFor="let message of messages; let i = index">
      <div
        *ngIf="i === currentSlide"
        class="text-center"
      >
        <div class="header">Neuralign</div>
        <div class="container">
          <button
            *ngIf="currentSlide > 0"
            class="control prev"
            (click)="onPreviousClick(message)"
          >
            <span class="arrow left"></span>
          </button>
          <img
            class="img"
            [src]="message.image"
            alt="Image"
          />
          <button
            *ngIf="currentSlide < messages.length - 1"
            class="control next"
            (click)="onNextClick(message)"
          >
            <span class="arrow right"></span>
          </button>
        </div>
        <div class="content">{{message.text}}</div>

        <div class="group-button">
          <button (click)="goToCourse(message)">Go to course</button>
          <button
            class="button-close"
            (click)="close(message)"
          >
            Close
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
