import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../logger/logger.service';
import { throwError } from 'rxjs';

interface RestOptions {
  msgOverwrite?: string;
  params?: HttpParams;
  //@deprecated
  msg?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RestAPIService {
  private server: string = environment.API_URL;

  constructor(private http: HttpClient, private logger: LoggerService) {}

  public get(endpoint, options?: RestOptions): Promise<any> {
    const req = this.constructRequest('GET', endpoint, options);
    return this.makeRequest(req);
  }

  public post(endpoint, body: any, options?: RestOptions): Promise<any> {
    const req = this.constructRequest('POST', endpoint, options, body);
    return this.makeRequest(req);
  }

  public put(endpoint, body: any, options?: RestOptions): Promise<any> {
    const req = this.constructRequest('PUT', endpoint, options, body);
    return this.makeRequest(req);
  }

  public delete(endpoint, options?: RestOptions): Promise<any> {
    const req = this.constructRequest('DELETE', endpoint, options);
    return this.makeRequest(req);
  }

  // /* Helpers */

  private constructRequest(method, endpoint: string, options?: RestOptions, body?: any) {
    const url = this.server + '/' + endpoint;
    const appendBody = { ...body, msg: options?.msg };

    return new HttpRequest(method, url, appendBody, { params: options?.params });
  }

  public async makeRequest(req): Promise<any> {
    try {
      const data = await this.http.request(req.method, req.url, { body: req.body, params: req.params }).toPromise();

      return data;
    } catch (err) {
      this.logger.error(err);
      throwError(() => new Error(err.message));
    }
  }
}
