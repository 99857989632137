"use strict";

exports.__esModule = true;
var _react = require("react");
var _react2 = _interopRequireDefault(_react);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var PaneSeparator = function PaneSeparator() {
  return _react2.default.createElement("div", {
    className: "auth0-lock-pane-separator"
  });
};
exports.default = PaneSeparator;