import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import {
  faTrashAlt,
  faExclamationTriangle,
  faPlus,
  faMinus,
  faGamepad,
  faEdit,
  faRedo,
  faTag,
  faCheck,
  faSchool,
  faBagShopping,
  faCoins,
  faFileUpload,
  faFileLines,
  faSearch,
  faFilter,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { ProductStripeModalComponent } from 'src/app/pages/stripe/modals/product-stripe-modal/product-stripe-modal.component';
import { PriceStripeModalComponent } from 'src/app/pages/stripe/modals/price-stripe-modal/price-stripe-modal.component';
import { TaxStripeModalComponent } from 'src/app/pages/stripe/modals/tax-stripe-modal/tax-stripe-modal.component';

@Component({
  selector: 'stripe-table-action-icon',
  templateUrl: './stripe-table-action-icon.component.html',
  styleUrls: ['./stripe-table-action-icon.component.scss'],
})
export class StripeTableActionIconComponent implements OnInit {
  @Input() icon:
    | 'default'
    | 'trash'
    | 'plus'
    | 'minus'
    | 'edit'
    | 'redo'
    | 'game'
    | 'tag'
    | 'checkmark'
    | 'learning'
    | 'shop'
    | 'coins'
    | 'upload'
    | 'file'
    | 'search'
    | 'filter'
    | 'eye';
  @Input() tooltip = '';
  @Input() action;
  @Input() disabled = false;
  @Input() stripeDataModal: any;
  @Input() routeApi: string;
  @Input() disableHandleClick: boolean;

  public iconToDisplay: any = faExclamationTriangle;
  private readonly MODAL_WIDTH = '600px';

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon() {
    switch (this.icon) {
      case 'trash':
        this.iconToDisplay = faTrashAlt;
        break;
      case 'plus':
        this.iconToDisplay = faPlus;
        break;
      case 'minus':
        this.iconToDisplay = faMinus;
        break;
      case 'edit':
        this.iconToDisplay = faEdit;
        break;
      case 'game':
        this.iconToDisplay = faGamepad;
        break;
      case 'redo':
        this.iconToDisplay = faRedo;
        break;
      case 'tag':
        this.iconToDisplay = faTag;
        break;
      case 'checkmark':
        this.iconToDisplay = faCheck;
        break;
      case 'learning':
        this.iconToDisplay = faSchool;
        break;
      case 'shop':
        this.iconToDisplay = faBagShopping;
        break;
      case 'coins':
        this.iconToDisplay = faCoins;
        break;
      case 'upload':
        this.iconToDisplay = faFileUpload;
        break;
      case 'file':
        this.iconToDisplay = faFileLines;
        break;
      case 'search':
        this.iconToDisplay = faSearch;
        break;
      case 'filter':
        this.iconToDisplay = faFilter;
        break;
      case 'eye':
        this.iconToDisplay = faEye;
        break;
      default:
        this.iconToDisplay = faExclamationTriangle;
    }
  }

  public handleClick() {
    if (this.disableHandleClick) {
      return;
    }
    const modalComponent = this.getModalComponent(this.stripeDataModal.service);
    if (modalComponent) {
      this.dialog.open(modalComponent as any, {
        width: this.MODAL_WIDTH,
        disableClose: true,
        data: {
          type: this.stripeDataModal.type,
          service: this.stripeDataModal.service,
          routeApi: this.routeApi,
          typeHttp: this.stripeDataModal.typeHttp,
          stripeDataModal: this.stripeDataModal,
        },
      });
    }
  }

  private getModalComponent(service: string) {
    switch (service) {
      case 'product':
        return ProductStripeModalComponent;
      case 'price':
        return PriceStripeModalComponent;
      case 'tax_rate':
        return TaxStripeModalComponent;
      default:
        return null;
    }
  }
}
