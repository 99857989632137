import { Component, Input, OnChanges } from '@angular/core';
import { TextLessonContentFEM } from 'src/app/pages/courses/services/courses/courses.service.models';
import { EventService } from 'src/app/pages/courses/services/event/event.service';

@Component({
  selector: 'display-text-lesson',
  templateUrl: './text-lesson.component.html',
  styleUrls: ['./text-lesson.component.scss'],
})
export class TextLessonComponent implements OnChanges {
  @Input() content: TextLessonContentFEM;

  constructor(private _events: EventService) {}

  ngOnChanges(): void {
    this._events.emitIsReadyForNextLesson(true);
  }
}
