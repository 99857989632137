import { NgModule } from '@angular/core';
import { DisplayComponent } from './display.component';
import { QuizLessonComponent } from './quiz-lesson/quiz-lesson.component';
import { SlidesLessonComponent } from './slides-lesson/slides-lesson.component';
import { StartLessonComponent } from './start-lesson/start-lesson.component';
import { TextLessonComponent } from './text-lesson/text-lesson.component';
import { VideoLessonComponent } from './video-lesson/video-lesson.component';
import { CommonModule } from '@angular/common';
import { QuestionTitleComponent } from './quiz-lesson/question-title/question-title.component';
import { QuestionAnswerTypeComponent } from './quiz-lesson/question-answer-type/question-answer-type.component';
import { ChoiceListComponent } from './quiz-lesson/choice-list/choice-list.component';
import { ChoiceListItemComponent } from './quiz-lesson/choice-list-item/choice-list-item.component';
import { FeedbackComponent } from './quiz-lesson/feedback/feedback.component';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';

@NgModule({
  declarations: [
    DisplayComponent,
    QuizLessonComponent,
    SlidesLessonComponent,
    StartLessonComponent,
    TextLessonComponent,
    VideoLessonComponent,
    QuestionTitleComponent,
    QuestionAnswerTypeComponent,
    ChoiceListComponent,
    ChoiceListItemComponent,
    FeedbackComponent,
  ],
  imports: [CommonModule, SafeUrlModule],
  exports: [DisplayComponent],
})
export class DisplayModule {}
