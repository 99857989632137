import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AuthGuardService } from './services/auth/auth.guard';
import { ExcelService } from './services/excel/excel.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { TestAreaComponent } from './shared/components/test-area/test-area.component';

import { AppRoutingModule } from './app.routing.module';
import { CoreModule } from './core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PagesModule } from './pages/pages.module';
import { ProgramModule } from './pages/program/program.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { NgxMaskModule } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialComponentsModule } from './shared/modules/material-components.module';
import { AdminModule } from './pages/admin/admin.module';
import { CustomSnackBar } from './shared/components/custom-snackbar/custom-snackbar.component';
import { RenewTokenDialog } from './services/auth/renew-token-dialog/renew-token-dialog.component';
import { LayoutComponentsModule } from './shared/components/layout/layout-components.module';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { domainUrl } from './services/auth/auth-constants';
import { LearningSectionModule } from './pages/courses/pages/learning-section/learning-section.module';
import * as Sentry from '@sentry/angular-ivy';
import { SignupCompletionGuard } from './pages/org-signup-completion/signup-completion-guard/signup-completion-guard';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

@NgModule({
  declarations: [AppComponent, TestAreaComponent, CustomSnackBar, RenewTokenDialog],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    ProgramModule,
    FooterModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    AdminModule,
    FlexLayoutModule,
    MatSnackBarModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.protocol + '//' + environment.ROOT_DOMAIN_URL + domainUrl,
      },
    }),
    FormsModule,
    MaterialComponentsModule,
    CoreModule,
    LayoutComponentsModule,
    LearningSectionModule,
    NgxGpAutocompleteModule,
  ],
  providers: [
    AuthGuardService,
    CookieService,
    TranslateService,
    TranslateStore,
    ExcelService,
    SignupCompletionGuard,
    {
      provide: MatDialogRef, // this prevents the tutorial modal from crashing
      useValue: {},
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.GOOGLE.MAPS_API,
        language: 'en',
        libraries: ['places'],
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
