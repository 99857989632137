import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LessonsService } from 'src/app/pages/courses/services/lessons/lessons.service';

@Component({
  selector: 'main-view-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnDestroy {
  currentLesson$ = this._lessons.currentLesson$;
  currentSection$ = new BehaviorSubject(null);
  private _subscription: Subscription;

  constructor(private _lessons: LessonsService) {}

  ngOnInit() {
    this._subscription = this._lessons.currentSection$.subscribe((section) => {
      this.currentSection$.next(section);
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  setCurrentSection(section) {
    this.currentSection$.next(section);
  }

  hasSlideSections(): boolean {
    const currentSection = this.currentSection$.value;
    return currentSection && currentSection.type === 'slides';
  }

  hasQuizSections(): boolean {
    const currentSection = this.currentSection$.value;
    return currentSection && currentSection.type === 'quiz';
  }
}
