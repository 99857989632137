import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faTrash, IconDefinition, faSquarePen } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UpdateLanguageDialog } from './update-language-dialog/update-language-dialog';
import { Language } from '../themes/interfaces/themes.interface';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  constructor(
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    private confirm: ConfirmationService,
    private dialog: MatDialog,
  ) {}

  public readonly trash: IconDefinition = faTrash;
  public readonly update: IconDefinition = faSquarePen;

  @Input() languagesList: Language[];
  @Output() reset = new EventEmitter();

  public name: string;
  public languageCode: string;
  public flagCode: string;

  public addingNewLang = false;

  public readonly languagesColumns: string[] = ['name', 'languageCode', 'flagCode', 'actions'];

  ngOnInit(): void {}

  public async saveNewLanguage() {
    const newLang = {
      name: this.name,
      languageCode: this.languageCode,
    };

    this._rest.post('languages', { newLang }, { msg: 'Could not create this language' }).then(() => {
      this._snackBar.open(`New language saved!!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.reset.emit();
      this.addingNewLang = false;
      this.resetLanguageForm();
    }),
      (err) =>
        this._snackBar.open(`Could not create this language, ${err}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
  }

  public openUpdateLanguageDialog(language) {
    const dialog = this.dialog.open(UpdateLanguageDialog, {
      data: language,
      width: '450px',
      height: '450px',
      panelClass: 'theme-modalBox',
    });

    dialog.afterClosed().subscribe(async (shouldSave) => {
      if (shouldSave) {
        await this.updateLanguage(language);
      }
    });
  }

  public async updateLanguage(language) {
    this._rest.put('languages/id/' + language.id, { language }, { msg: 'Could not update this language' }).then(() => {
      this._snackBar.open(`Language updated!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }),
      (err) =>
        this._snackBar.open(`Could not update this language, ${err}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
  }

  public deleteLanguage(id: string) {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this language?', 'Yes', 'No')
      .then(async () => {
        this._rest.delete('languages/id/' + id, { msg: 'Could not delete this language' }).then(() => {
          this._snackBar.open(`Language deleted`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }),
          this.reset.emit();
        (err) =>
          this._snackBar.open(`${err}`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
      });
  }

  public resetLanguageForm() {
    this.name = '';
    this.languageCode = '';
  }
}
