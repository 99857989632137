import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '../../services/courses/courses.service';

@Component({
  selector: 'courses-learning-section',
  templateUrl: './learning-section.component.html',
  styleUrls: ['./learning-section.component.scss'],
})
export class LearningSectionPageComponent implements OnInit {
  constructor(private _courses: CoursesService, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._courses.loadCourseByActivatedRoute(this._route);
  }
}
