import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faMinus, faPlus, faSquare } from '@fortawesome/free-solid-svg-icons';
import { SideMenuService } from '../side-menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-menu-section',
  templateUrl: './side-menu-section.component.html',
  styleUrls: ['./side-menu-section.component.scss'],
})
export class SideMenuSectionComponent {
  @Input() title = '';
  @Input() icon = faSquare;
  @Input() plusIcon = faPlus;
  @Input() minusIcon = faMinus;
  @Input() hidePlusIcon = false;
  @Input() tooltip = '';
  @Input() sectionKey = '';

  @Output() onOpenSection = new EventEmitter();

  isSideMenuOpen = false;
  isDropdownOpen = false;

  sideMenuSub: Subscription;

  constructor(private _sideMenuService: SideMenuService) {}

  ngOnInit() {
    this.sideMenuSub = this._sideMenuService.sideMenu$.subscribe((data) => {
      this.isSideMenuOpen = data.isSideMenuOpen;

      if (data.sections[this.sectionKey]) {
        this.isDropdownOpen = true;
      } else {
        this.isDropdownOpen = false;
      }
    });
  }

  public toggleSection() {
    this._sideMenuService.toggleSection(this.title);
  }

  public openSection() {
    this._sideMenuService.openSideMenu();
    this._sideMenuService.toggleSection(this.title);
  }
}
