import { LessonEnum, LessonType } from '../../utils/LessonType.type';

export interface CourseFEM {
  id: string;
  title: string;
  description: string;
  hasCourseStarted: boolean;
  percentOfCourseComplete: number;
  isCourseComplete: boolean;
  numberOfLessons: number;
  image: string;
  numberOfSections: number;
  lessons: LessonFEM[];
  hasCertificate: boolean;
}

export interface LessonFEM {
  id: string;
  title: string;
  isCompleted: boolean;
  sequence: number;
  sections: SectionFEM[];
  numberOfLessons: number;
  isDraft: boolean;
  index: number;
  isFirstInCourse: boolean;
  isLastInCourse: boolean;
}

export interface SectionFEM {
  id: string;
  title: string;
  isCompleted: boolean;
  type: LessonType;
  content: LessonContentFEM;
  isDraft: boolean;
  isFirstInLesson: boolean;
  isLastInLesson: boolean;
  sequence: number;
  index: number;
  isSelected: boolean;
  belongsTo: string;
}

export type TextLessonContentFEM = {
  text: string;
};
export type QuizLessonContentFEM = {
  quizes: QuizFEM;
};
export type SlidesLessonContentFEM = {
  slides: string[];
};
export type VideoLessonContentFEM = {
  text: string;
  url: string;
  title?: string;
  completed?: boolean;
  id?: string;
  isLast?: boolean;
};
export type LessonContentFEM =
  | TextLessonContentFEM
  | QuizLessonContentFEM
  | SlidesLessonContentFEM
  | VideoLessonContentFEM;

export interface QuizFEM {
  choices: any[];
  correctQuestion: string;
  feedBack: string;
  isOptional: boolean;
  question: string;
  selectedChoices: any[];
  type: string;
}

export interface CourseLessonsList {
  courseId: string;
  lessons: LessonFEM[];
}

export interface CourseLessonsList {
  lessons: LessonFEM[];
}

export interface QuizQuestion {
  question: string;
  type: QuizQuestionType;
  typeLabel: string;
  choices: any[];
  feedback: string;
  isOptional: boolean;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  index: number;
}

export enum QuizQuestionType {
  SingleAnswer = 'single',
  MulitAnswer = 'multi',
}

export const defaultCourse: CourseFEM = {
  id: 'mock-course-id',
  title: 'default course title',
  description: 'default course description',
  image: 'default-image-path',
  hasCertificate: true,
  lessons: [
    {
      id: 'mock-section-id',
      title: 'default section title',
      isCompleted: false,
      isDraft: true,
      sections: [
        {
          id: 'mock-lesson-id',
          title: 'default lesson title',
          type: LessonEnum.Text,
          content: {
            text: 'default text lesson content',
          },
          index: 0,
          isCompleted: false,
          isDraft: true,
          isFirstInLesson: true,
          isLastInLesson: false,
          isSelected: true,
          sequence: 1,
          belongsTo: '',
        },
      ],
      numberOfLessons: 1,
      sequence: 1,
      index: 0,
      isFirstInCourse: true,
      isLastInCourse: true,
    },
  ],
  hasCourseStarted: false,
  isCourseComplete: false,
  numberOfLessons: 1,
  numberOfSections: 1,
  percentOfCourseComplete: 0,
};

export const defaultLessons = defaultCourse.lessons;
export const defaultSections = defaultCourse.lessons.map((s) => s.sections).flat();

export interface SlideFEM {
  url: string;
  sequence: number;
  numberOfSlides: number;
  isLastSlide: boolean;
}

export const defaultSlide: SlideFEM = {
  url: '',
  sequence: 1,
  numberOfSlides: 1,
  isLastSlide: false,
};
