<div *ngIf="!loading">
  <ng-container
    *ngIf="userIsBuyingForFirstTime; then firstPurchase; else regularPurchase"
  ></ng-container>

  <ng-template #firstPurchase>
    <div class="margin centered">
      <pricing-heading
        text="Join the growing list of trusted Neuralign professionals"
      ></pricing-heading>
      <pricing-paragraph
        text="Our sign-on price includes a comprehensive online provider certification course"
      ></pricing-paragraph>
      <pricing-paragraph
        text="2 Neuralign credits to familiarize yourself with the program, and a Neuralign portal with a unique URL that can be branded with your centre's logo."
      ></pricing-paragraph>
    </div>

    <single-card-holder>
      <!-- <span class="badge">Special Welcome Offer!</span> -->
      <pricing-card class="position-relative">
        <div
          class="position-absolute priceHeader"
          *ngIf="
            firstPurchaseBundle.discount && firstPurchaseBundle.discount > 0
          "
        >
          {{ firstPurchaseBundle.discount + '% discount!' }}
        </div>
        <card-heading> Provider Certification </card-heading>
        <card-price
          [fullPrice]="firstPurchaseBundle?.fullPrice"
          [discount]="firstPurchaseBundle?.discount"
          [currency]="firstPurchaseBundle?.currency"
          [price]="firstPurchaseBundle?.price"
          [isSubscription]="firstPurchaseBundle?.subscription"
        ></card-price>
        <ul>
          <li>Provider certification course</li>
          <li>2 Neuralign credits</li>
          <li>Branded portal</li>
        </ul>
        <card-buy-btn [bundle]="firstPurchaseBundle"></card-buy-btn>
      </pricing-card>
    </single-card-holder>
  </ng-template>

  <ng-template #regularPurchase>
    <div class="margin centered">
      <pricing-heading
        text="Choose the plan that's right for you."
      ></pricing-heading>
      <pricing-paragraph
        text="The world's premium, cognitive, research based reading intervention, is now available as a subscription, making it more affordable than ever to offer it to your clients."
      ></pricing-paragraph>
    </div>

    <br />

    <div class="d-flex justify-content-center">
      <div *ngFor="let bundle of bundles">
        <single-card-holder>
          <pricing-card class="position-relative">
            <div
              class="position-absolute priceHeader"
              *ngIf="bundle.discount && bundle.discount > 0"
            >
              {{ bundle.discount + '% discount!' }}
            </div>
            <card-heading>
              {{ bundle.studentsIncluded }} Neuralign Program credits
            </card-heading>
            <card-price
              [fullPrice]="bundle?.fullPrice"
              [discount]="bundle?.discount"
              [currency]="bundle?.currency"
              [price]="bundle?.price"
              [isSubscription]="bundle?.subscription"
            ></card-price>
            <card-buy-btn [bundle]="bundle"></card-buy-btn>
          </pricing-card>
        </single-card-holder>
      </div>
    </div>
  </ng-template>

  <br />
  <br />
  <div class="text-center">
    <p>Each credit provides access to Neuralign for one student.</p>
    <p>All providers must complete the Neuralign provider certification.</p>
  </div>

  <br />
  <br />
</div>

<div
  class="p-5 d-flex justify-content-center align-items-center w-100 loading"
  *ngIf="loading"
>
  <fa-icon
    [icon]="spinnerIcon"
    [spin]="true"
  >
  </fa-icon>
</div>
