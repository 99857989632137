import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { LearningSectionPageComponent } from './learning-section.component';
import { CourseCompletionComponent } from './course-completion/course-completion.component';

import { MainViewModule } from './main-view/main-view.module';
import { GridComponent } from './grid/grid.component';
import { HeaderComponent } from './header/header.component';
import { CoursesSharedModule } from '../../shared/courses.shared.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';
import { LessonsListModule } from './lessons-list/lessons-list.module';

@NgModule({
  declarations: [LearningSectionPageComponent, CourseCompletionComponent, GridComponent, HeaderComponent],
  imports: [CommonModule, LayoutsModule, LessonsListModule, MainViewModule, CoursesSharedModule, CurtainModule],
})
export class LearningSectionModule {}
