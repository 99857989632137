import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StripeTax, StripeProduct, StripePrice } from 'src/app/shared/interfaces/Stripe.interfaces';

@Injectable({
  providedIn: 'root',
})
export class StripeObservableService {
  private taxSource = new BehaviorSubject<StripeTax>(null);
  currentTax = this.taxSource.asObservable();

  private priceSource = new BehaviorSubject<StripePrice>(null);
  currentPrice = this.priceSource.asObservable();

  private productSource = new BehaviorSubject<StripeProduct>(null);
  currentProduct = this.productSource.asObservable();

  constructor() {}

  changeTax(tax: StripeTax) {
    this.taxSource.next(tax);
  }

  changePrice(price: StripePrice) {
    this.priceSource.next(price);
  }

  changeProduct(product: StripeProduct) {
    this.productSource.next(product);
  }

  public buildPriceData(row: StripePrice) {
    return {
      id: row.id,
      priceType: row.type,
      priceCurrency: row.currency,
      priceUnitAmount: row.unit_amount,
      priceInterval: row.recurring?.interval,
      priceActive: row.active,
      priceProduct: row.product,
      priceNickname: row.nickname,
      priceQuantityTokens: row.metadata?.tokens,
      priceFirstPurchase: row.metadata?.first_purchase,
    };
  }

  public buildTaxRateData(row: StripeTax) {
    return {
      id: row.id,
      taxType: row.display_name,
      taxCountry: row.country,
      taxState: row.state,
      taxPercentage: row.percentage,
      taxInclusive: row.inclusive,
      taxActive: row.active,
    };
  }

  public buildProductData(row: StripeProduct) {
    return {
      id: row.id,
      productName: row.name,
      productDescription: row.description,
      productActive: row.active,
      productPriceCurrency: row.currency,
      productPriceUnitAmount: row.unit_amount,
      productPriceInterval: row.interval,
      productDefaultPrice: row.default_price,
      productTax: row.metadata?.tax_code,
    };
  }
}
