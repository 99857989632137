import { camelCase } from 'lodash';
import { progressTypes } from 'src/app/shared/consts/global-constants';
import { ThemeCategory } from '../interfaces/themes.interface';
import { Language } from '../interfaces/themes.interface';

export const readLsLevels = [
  {
    level: 'JR',
    enabled: false,
    assessmentLevel: 0,
  },
  {
    level: 'P1',
    enabled: true,
    assessmentLevel: 1,
  },
  {
    level: 'P2',
    enabled: true,
    assessmentLevel: 2,
  },
  {
    level: 'P3',
    enabled: true,
    assessmentLevel: 3,
  },
  {
    level: 'P4',
    enabled: true,
    assessmentLevel: 4,
  },
];

export const createNewTheme = (themeName: string, categories: ThemeCategory[], languages: Language[]) => {
  return {
    label: {
      en_ca: themeName,
      fr_ca: '',
    },
    categories: addCategories(categories),
    languages: languages,
    isDefault: false,
    isEnabled: false,
    variableName: camelCase(themeName),
  };
};

export const addCategories = (categories: ThemeCategory[]) => {
  const themeCategoriesList = categories.map((c) => {
    return {
      id: c.id,
      name: c.name,
      title: c.title,
    };
  });

  return themeCategoriesList;
};

export const getDefaultCategories = () => {
  return [
    { name: progressTypes.ASSESSMENT, enabled: true },
    { name: progressTypes.COGNITIVE_THERAPY, enabled: true },
    { name: progressTypes.SPEED_READING, enabled: true },
    { name: progressTypes.READING_EXERCISES, enabled: true },
  ];
};

export const getDefaultLevels = () => {
  return [
    {
      level: 'JR',
      enabled: false,
      assessmentLevel: 0,
    },
    {
      level: 'P1',
      enabled: true,
      assessmentLevel: 1,
    },
    {
      level: 'P2',
      enabled: true,
      assessmentLevel: 2,
    },
    {
      level: 'P3',
      enabled: true,
      assessmentLevel: 3,
    },
    {
      level: 'P4',
      enabled: true,
      assessmentLevel: 4,
    },
  ];
};
