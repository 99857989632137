<div
  id="Register"
  class="inner-content"
>
  <h2 *ngIf="formType === 'Organization' || formType === 'Client'">
    {{ formType === 'Organization' ? 'Organization' : 'Client' }}
  </h2>

  <h2 *ngIf="formType === 'Password'">Create New Password</h2>

  <ng-container [ngSwitch]="formType">
    <form
      [formGroup]="patronFormGroup"
      *ngSwitchCase="'Patron'"
    >
      <div class="two-columns">
        <mat-form-field appearance="outline">
          <mat-label>Full Name</mat-label>
          <input
            matInput
            required
            placeholder="Full Name"
            formControlName="name"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input
            matInput
            required
            placeholder="E-mail"
            [attr.disabled]="disabled ? '' : null"
            formControlName="email"
          />
        </mat-form-field>
      </div>
      <div class="two-columns">
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <input
            matInput
            required
            placeholder="Country"
            formControlName="country"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Postal Code</mat-label>
          <input
            matInput
            required
            placeholder="Postal Code"
            formControlName="postalCode"
            class="postalCode"
          />
        </mat-form-field>
      </div>

      <form
        *ngIf="!editing"
        [formGroup]="passwordFormGroup"
        autocomplete="off"
      >
        <div class="two-columns">
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              type="password"
              matInput
              required
              placeholder="New password"
              formControlName="password"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Confirm password</mat-label>
            <input
              type="password"
              matInput
              required
              placeholder="Confirm password"
              formControlName="confirmPassword"
            />
          </mat-form-field>
        </div>
        <span
          >At least 8 characters including at least 3 of the following 4 types
          of characters:</span
        >
        <ul style="list-style-type: circle">
          <li>A lower-case letter</li>
          <li>An upper-case letter</li>
          <li>A number</li>
        </ul>
      </form>
    </form>

    <form
      [formGroup]="passwordFormGroup"
      *ngSwitchCase="'Password'"
      autocomplete="off"
    >
      <div class="two-columns">
        <mat-form-field appearance="outline">
          <mat-label>New password</mat-label>
          <input
            type="password"
            matInput
            required
            placeholder="New password"
            formControlName="password"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Confirm password</mat-label>
          <input
            type="password"
            matInput
            required
            placeholder="Confirm password"
            formControlName="confirmPassword"
          />
        </mat-form-field>
      </div>
      <span
        >At least 8 characters including at least 3 of the following 4 types of
        characters:</span
      >
      <ul style="list-style-type: circle">
        <li>A lower-case letter</li>
        <li>An upper-case letter</li>
        <li>A number</li>
        <li>A special character (such as !&#64;#$%^&*)</li>
      </ul>
    </form>

    <form
      [formGroup]="orgFormGroup"
      *ngSwitchCase="
        formType === 'Organization' || formType === 'Outsider' ? formType : ''
      "
      autocomplete="off"
    >
      <div class="two-columns">
        <div class="logo">
          <h3 class="logo mx-auto">Logo</h3>
          <img
            class="mx-auto"
            [src]="getOrgLogo()"
          />
          <app-simple-button
            class="mx-auto mt-3"
            text="UPLOAD IMAGE"
            (click)="uploadToCloudinary()"
          ></app-simple-button>
        </div>

        <div>
          <h3>Basic Info</h3>
          <mat-form-field appearance="outline">
            <mat-label>Organization Name</mat-label>
            <input
              matInput
              required
              placeholder="Organization Name"
              formControlName="name"
              (blur)="fillSubdomain()"
            />
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="editing === false"
          >
            <mat-label>Email</mat-label>
            <input
              matInput
              required
              placeholder="Owner Email"
              formControlName="email"
            />
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="stable"
          >
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              required
              placeholder="Phone Number"
              formControlName="phoneNumber"
            />
          </mat-form-field>
          <div class="two-columns">
            <mat-form-field appearance="outline">
              <mat-label>Profession</mat-label>
              <input
                matInput
                required
                placeholder="Profession"
                formControlName="position"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Professional Association #</mat-label>
              <input
                matInput
                placeholder="Professional Association #"
                formControlName="professionalAssociation"
                type="number"
              />
            </mat-form-field>
          </div>
          <ng-container formGroupName="address">
            <div class="two-columns">
              <div class="address-field-container">
                <mat-form-field appearance="outline">
                  <mat-label>Address (number & street, city)</mat-label>
                  <input
                    matInput
                    formControlName="address"
                    placeholder="Address"
                    required
                    id="autocomplete"
                    placeholder="Enter a place"
                    type="text"
                    (blur)="fillFormControl('address')"
                    class="address"
                    ngx-gp-autocomplete
                    (onAddressChange)="handleAddressChange($event)"
                    type="search"
                  />
                </mat-form-field>
                <p>
                  Please use autocomplete to fill out your address information.
                </p>
              </div>
              <div class="address-field-container">
                <mat-form-field appearance="outline">
                  <mat-label>Postal/Zip Code</mat-label>
                  <input
                    (blur)="fillFormControl('postalCode')"
                    matInput
                    required
                    placeholder="Postal/Zip Code"
                    formControlName="postalCode"
                    class="postalCode"
                  />
                </mat-form-field>
                <p>
                  <!-- hack to fix layout -->
                </p>
              </div>
            </div>
            <div class="two-columns">
              <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input
                  matInput
                  required
                  placeholder="Country"
                  formControlName="country"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Province/State</mat-label>
                <input
                  matInput
                  required
                  placeholder="Province"
                  formControlName="province"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <mat-form-field
            appearance="outline"
            class="stable"
          >
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option
                *ngFor="let language of languages"
                [value]="language.value"
              >
                {{ language.title }}
              </mat-option>
            </mat-select>
            <!-- <input matInput required
              placeholder="Phone Number"
              formControlName="phoneNumber"
            /> -->
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="stable"
          >
            <mat-label>Referred by</mat-label>
            <input
              matInput
              placeholder="Referred by"
              formControlName="referredBy"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="subdomain">
        <span>https://</span>
        <mat-form-field appearance="outline">
          <mat-label>Subdomain</mat-label>
          <input
            matInput
            required
            placeholder="Subdomain"
            formControlName="subdomain"
          />
        </mat-form-field>
        <span>.ls.works</span>
      </div>
      <div
        class="terms"
        *ngIf="!isOutsiderForm() && !editing"
      >
        <mat-checkbox formControlName="acceptedTerms">
          I accept the
          <a
            target="_blank"
            routerLink="/terms-of-service"
          >
            Terms of Service
          </a>
        </mat-checkbox>
      </div>
      <br /><br />
    </form>
  </ng-container>

  <div class="save">
    <app-simple-button
      *ngIf="!savingUser"
      [text]="editing ? 'SAVE' : 'REGISTER'"
      [disabled]="
        formType === 'Password' || (formType === 'Patron' && !editing)
          ? !isPasswordValid()
          : formType === 'Patron'
          ? !patronFormGroup.valid
          : !isOrgFormValid()
      "
      (click)="saveUser()"
    >
    </app-simple-button>

    <div
      class="m-auto"
      *ngIf="savingUser"
    >
      We're saving your information, please wait
      <fa-icon
        [icon]="spinner"
        [spin]="true"
      ></fa-icon>
    </div>
    <p *ngIf="checkFormDisabled()">
      Please check the form, there are invalid fields or required fields
      missing.
    </p>

    <p
      *ngIf="
        passMatch() === false &&
        passwordFormGroup.get('password').value.length >= 8 &&
        passwordFormGroup.get('confirmPassword').value.length >= 8
      "
    >
      Passwords does not matches.
    </p>
  </div>
</div>
