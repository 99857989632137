import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'learning-section-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public logo: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.setLogo();
  }

  private setLogo(): void {
    this.logo = 'assets/img/neuralign-text-logo-white.png';
  }

  // Handlers

  public handleNavagateToPortal() {
    this.router.navigate(['']);
  }
}
