import { Component, Input, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { StripeObservableService } from '../stripe-observable.service';
import { StripeHttpMethod, StripePrice, StripeProduct, StripeTax } from 'src/app/shared/interfaces/Stripe.interfaces';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';

@Component({
  selector: 'app-stripe-dashboard',
  templateUrl: './stripe-table-dashboard.component.html',
  styleUrls: ['./stripe-table-dashboard.component.scss'],
})
export class StripeTableDashboardComponent implements OnInit {
  @Input() columns: string[];
  @Input() rows: any[];
  @Input() routeApi: string;

  //Config
  public sortDirection = 'asc';
  sortField = null;

  constructor(
    private stripeObservableService: StripeObservableService,
    private _rest: RestAPIService,
    private confirm: ConfirmationService,
  ) {}

  ngOnInit(): void {}

  public sortTable(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }

    this.rows.sort((a, b) => {
      if (a[this.sortField] < b[this.sortField]) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (a[this.sortField] > b[this.sortField]) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  getColumnDisplayName(column: string): string {
    switch (column) {
      case 'display_name':
        return 'name';
      case 'product_name':
        return 'product name';
      case 'nickname':
        return 'name';
      case 'unit_amount':
        return 'price';
      case 'metadata.tokens':
        return 'tokens';
      default:
        return column;
    }
  }

  getColumnValue(row: any, column: string) {
    switch (column) {
      case 'active':
        return row[column] === true ? 'Enabled' : 'Disabled';
      case 'inclusive':
        return row[column] === true ? 'Included in the price' : 'Not included in the price';
      case 'unit_amount':
        return row[column] > 0 ? row[column] / 100 : '';
      case row.object === 'price' && 'type':
        return row[column] === 'one_time' ? 'One Time' : 'Recurring';
      case 'recurring':
        return row[column]?.interval ? row[column].interval.toUpperCase() : 'Not recurring';
      case 'interval':
        return row[column]?.interval ? row[column].interval.toUpperCase() : 'Not recurring';
      case 'metadata.tokens':
        return row['metadata']?.tokens ? row['metadata'].tokens : 0;
      default:
        return typeof row[column] === 'string' ? row[column].toUpperCase() : row[column];
    }
  }

  public getStripeDataModal(row: StripeTax | StripeProduct | StripePrice, typeHttp: StripeHttpMethod, type?: string) {
    let data;
    switch (row.object) {
      case 'price':
        data = this.stripeObservableService.buildPriceData(row as StripePrice);
        break;
      case 'tax_rate':
        data = this.stripeObservableService.buildTaxRateData(row as StripeTax);
        break;
      case 'product':
        data = this.stripeObservableService.buildProductData(row as StripeProduct);
        break;
      default:
        return null;
    }

    return {
      type: type,
      service: row.object,
      typeHttp: typeHttp,
      routeApi: this.routeApi,
      data: data,
    };
  }

  public async deleteItem(row: StripeTax | StripeProduct | StripePrice) {
    try {
      const name = this.getNameForRow(row);
      const confirm = await this.confirm.createConfirmation('Neuralign', `Do you want to delete ${name}?`, 'Yes', 'No');
      if (!confirm) return;

      const url = this.routeApi + '/id/' + row.id;
      row.active = false;
      this.rows = this.rows.filter((item) => item.id !== row.id);

      switch (row.object) {
        case 'product':
          this.stripeObservableService.changeProduct(row as StripeProduct);
          break;
        case 'price':
          this.stripeObservableService.changePrice(row as StripePrice);
          break;
        case 'tax_rate':
          this.stripeObservableService.changeTax(row as StripeTax);
          break;
      }
      await this._rest.delete(url);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  private getNameForRow(row) {
    switch (row.object) {
      case 'product':
        return row.name as StripeProduct;
      case 'price':
        return row.nickname as StripePrice;
      case 'tax_rate':
        return row.display_name as StripeTax;
    }
  }
}
