import { Component } from '@angular/core';
import { SlidesService } from 'src/app/pages/courses/services/slides/slides.service';

@Component({
  selector: 'prev-slide-button',
  templateUrl: './prev-slide-button.component.html',
  styleUrls: ['./prev-slide-button.component.scss'],
})
export class PrevSlideButtonComponent {
  public currentSlide$ = this._slides.currentSlide$;

  constructor(private _slides: SlidesService) {}

  handleClick() {
    this._slides.selectPrevSlide();
  }
}
